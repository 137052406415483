import React, { useEffect, useState, useContext } from 'react'
import DashboardLayout from '../../../../components/layout/dashboard/DashboardLayout'
import { useTranslation } from 'react-i18next';
import './comptes.css'
import AuthContext from '../../../../contexte/AuthContext';
import { fetchAccounts } from 'services/fetchAccounts';
import Tableau from 'components/DataComponents/Tableau/Tableau';
import PageLoader from 'components/pageLoader/PageLoader';
import Badge from 'components/Badge/Badge';
import colors from 'assets/colors';
import { launchError } from 'components/notifications';
import Notification from 'components/notifications/Notification';

const Comptes = () => {

  const items = [
    {
      name: 'Stripe',
      key: 'stripe'
    },
    {
      name: 'Alpaca',
      key: 'alpaca'
    },
    {
      name: 'Revolut',
      key: 'revolut'
    },
    {
      name: 'Currency Cloud',
      key: 'currency_cloud'
    }
  ]

  const { t } = useTranslation()
  const { user } = useContext(AuthContext)

  const [accountData, setAccountData] = useState({})
  const [loading, setLoading] = useState(true)

  const fetchData = async () => {

    try {
      const accountsData = await fetchAccounts(user)
      setAccountData(accountsData)
      setLoading(false)

    } catch (e) {
      launchError(t('notifications.global.errors.fetch'))
    }

  }

  useEffect(() => {
    fetchData();
  }, [])

  function transformDataToTBody(data) {

    return data.map(item => {
      console.log('item.id', item.id, item);
      return {
        data: [
          item.account_name,
          item.balances.map(balance => (
            <p key={balance.id}>{balance.amount} {balance.currency}</p>
          )),
          <Badge key={item.id || item.account_name} color={colors.badge[item.status?.toUpperCase()]}
            text={item.status?.toUpperCase()} />
        ]
      };
    });
  }

  return (
    <DashboardLayout page={t("pages_name.finances_comptes")}>
      {!loading ? (
        <div>
          <Notification />
          <h2>{t("pages.comptes.title")}</h2>
          <div className="table-container">
            {
              items.map((item, index) => {
                return (
                  <div className="table-column" key={index}>
                    <h3>{item.name}</h3>
                    <Tableau
                      key={item.key}
                      thead={[t("pages.comptes.account_name"), t("pages.comptes.balance"), t("pages.comptes.status")]}
                      tbody={transformDataToTBody(accountData[item.key])}
                    />
                  </div>
                )
              })
            }

          </div>
        </div>
      ) : (
        <div className='pageLoaderBox'>
          <PageLoader />
        </div>
      )}
    </DashboardLayout>
  )
}

export default Comptes