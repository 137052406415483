import { useState, useEffect, useContext } from 'react'
import DashboardLayout from 'components/layout/dashboard/DashboardLayout'
import { useTranslation } from 'react-i18next';
import './iban.css'
import AuthContext from 'contexte/AuthContext';
import Tableau from 'components/DataComponents/Tableau/Tableau';
import { fetchIbans } from 'services/ibanServices';
import FilterComponent from 'components/Filters/FilterComponent/FilterComponent';
import PageLoader from 'components/pageLoader/PageLoader';
import { formatIBAN } from 'utils/iban';
import { RELEASE_DATE, formatDate } from 'utils/date';

const Iban = () => {

    const { t } = useTranslation()
    const { user } = useContext(AuthContext)

    const [ibans, setIbans] = useState([])
    const [loading, setLoading] = useState(true)
    const [fetchLimit, setFetchLimit] = useState(10)
    const [page, setPage] = useState(0)
    const [selectedDate, setSelectedDate] = useState({
        date1: { startDate: new Date(RELEASE_DATE), endDate: new Date() },
        date2: { startDate: null, endDate: null },
    });

    const fetchData = async () => {

        try {

            const ibansData = await fetchIbans(user, page, fetchLimit, selectedDate.date1.startDate, selectedDate.date1.endDate)

            setIbans(ibansData)
            setLoading(false)

        } catch (e) {
            console.log(e)
        }

    }


    useEffect(() => {
        if (selectedDate.date1.startDate !== null && selectedDate.date1.endDate !== null) {
            fetchData()
        }
    }, [fetchLimit, page, selectedDate])

    const resetFilter = () => {
        setSelectedDate({
            date1: { startDate: new Date(RELEASE_DATE), endDate: new Date() },
            date2: { startDate: null, endDate: null },
        })
        setFetchLimit(50)
    }

    const transformDataToTbody = (data) => {
        return data.map((item) => {
            return {
                data: [
                    formatDate({date: item.created_at, month: 'long', time: true}),
                    `${item.client_firstName} ${item.client_lastName}`,
                    formatIBAN(item.iban),
                    item.bank_name,
                ], utils: {
                    id: item.bank_account_id
                }
            }
        })
    }

    const handleNextPage = () => {
        setPage(page + 1)
    }

    return (
        <DashboardLayout page={t("pages_name.ibans")}>
            <div>
                {!loading ? (
                    <div>
                        <div className='externalDeposit_titleComponent'>
                            <h2>Liste Des Ibans En Attente De Vérification</h2>
                            <FilterComponent
                                resetFilter={resetFilter}
                                limit={true}
                                date={true}
                                fetchLimit={fetchLimit}
                                setFetchLimit={setFetchLimit}
                                selectedDate={selectedDate}
                                setSelectedDate={setSelectedDate}
                            />
                        </div>

                        <div className="table-container">
                            <div className="table-column">
                                <Tableau
                                    thead={["Date", "Client", "Iban", "Nom de banque"]}
                                    tbody={transformDataToTbody(ibans)}
                                    hadLink={true}
                                    linkPath={"/finances/iban/"}
                                    handleNextPage={handleNextPage}
                                    limit={fetchLimit}
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className='pageLoaderBox'>
                        <PageLoader />
                    </div>
                )}
            </div>
        </DashboardLayout>
    )
}

export default Iban