import { useState, useEffect, useContext } from 'react'
import AuthContext from 'contexte/AuthContext'
import { fetchTransactions } from 'services/fetchUserById'
import PageLoader from 'components/pageLoader/PageLoader'
import Tableau from 'components/DataComponents/Tableau/Tableau'
import { formatDate } from 'utils/date';
import './UserTransactions.css'
import Modal from 'components/modal/Modal'
import { useTranslation } from 'react-i18next'
import { fetchPlaylistData } from 'services/fetchPlaylistData'
import TickerImage from 'components/DataComponents/TickerImage/TickerImage'

const UserTransactions = ({ id }) => {

    const { user } = useContext(AuthContext)
    const { t } = useTranslation()

    const [loading, setLoading] = useState(true)
    const [transactions, setTransactions] = useState([])
    const [page, setPage] = useState(0)
    const [fetchLimit, setFetchLimit] = useState(10)
    const [playlistData, setPlaylistData] = useState([])
    const [playlistLoading, setPlaylistLoading] = useState(true)

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [modalIndex, setModalIndex] = useState(0)

    const fetchData = async () => {
        try {
            const getUserTransactions = await fetchTransactions(user, id, page, fetchLimit)
            setTransactions(getUserTransactions)
            setLoading(false)
        } catch {
            console.log('error')
        }
    }

    useEffect(() => {
        fetchData()
    }, [page])

    const transformeDataToTbody = (data) => {
        return data.map((item, index) => {
            return {
                data: [
                    !item.playlist_id ?
                        <TickerImage ticker={item.ticker} />
                        :
                        <div className='arrayTickerItem'>
                            <span className="text-xs font-weight-bold me-3" style={{ color: "#A270FF" }}>◉</span>
                            <p style={{ margin: 0 }}>{item.ticker}</p>
                        </div>,
                    item.qty,
                    item.alpaca_status,
                    item.ticker_value,
                    item.totalEur,
                    item.type === 'BUY' ? (<span className='span_buy'>Achat</span>) : (<span className='span_sell'>Vente</span>),
                    item.playlist_id !== null ? "PLAYLIST" : "UNIQUE",
                    formatDate({ date: item.created_at, time: true })
                ],
                utils: { id: (item.playlist_id ? 'playlist' : 'unique') + `/${item.invest_uuid}` }
            }
        })
    }


    const transformePlaylistDataToTbody = (data) => {
        return data
            .filter(item => item.ticker !== "TOTAL")
            .map((item, index) => {
                return {
                    data: [
                        <TickerImage ticker={item.ticker} />,
                        item.cost_basis,
                        formatDate({ date: transactions[modalIndex].created_at, time: true })
                    ]
                }
            })
    }

    const onClickFunction = (index) => {
        if (transactions[index].playlist_id !== null) {
            setModalIndex(index)
            setIsModalOpen(true);
        }
    }

    const onMountFunction = async () => {
        try {
            const playlistId = transactions[modalIndex].playlist_id
            const fetchPlaylist = await fetchPlaylistData(user, id, playlistId)
            setPlaylistData(fetchPlaylist)
            setPlaylistLoading(false)
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div>
            {
                !loading ? (
                    <div>
                        <Tableau
                            tbody={transformeDataToTbody(transactions)}
                            thead={["Ticker/playlist", 'Quantité', "Etat", 'Prix', 'Investissement(HT)', "Type d'ordre", "Type de trasaction", "Date"]}
                            limit={fetchLimit}
                            hadLink={true}
                            linkPath={"/finances/trades/"}
                            pagination={true}
                            setPage={setPage}
                            page={page}
                        />
                    </div>
                ) : (
                    <div className='pageLoaderBox'>
                        <PageLoader />
                    </div>
                )
            }
            {
                isModalOpen && (
                    <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} closeButton={true} triggerFonction={true} onMountFunction={onMountFunction}>
                        <h2 className='modal_title'>Détail de la playlist</h2>
                        {
                            !playlistLoading ? (
                                <div>
                                    <Tableau
                                        thead={["Ticker", 'Prix', "Date"]}
                                        tbody={transformePlaylistDataToTbody(playlistData)}
                                    />
                                </div>
                            ) : (
                                <div className='pageLoaderBox'>
                                    <PageLoader />
                                </div>
                            )
                        }
                    </Modal>
                )
            }
        </div>
    )
}

export default UserTransactions