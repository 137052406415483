import { useState, useContext, useEffect } from 'react'
import AuthContext from 'contexte/AuthContext'
import Notification from 'components/notifications/Notification'
import { launchError, launchSuccess } from 'components/notifications'
import { useTranslation } from 'react-i18next';
import DashboardLayout from 'components/layout/dashboard/DashboardLayout';
import './addMarginTicker.css'
import { addMarginTicker, fetchAvailableMarginTicker } from 'services/tickersServices';
import FilterComponent from 'components/Filters/FilterComponent/FilterComponent';
import Tableau from 'components/DataComponents/Tableau/Tableau';
import TickerImage from 'components/DataComponents/TickerImage/TickerImage';
import { formatNumberWithSpaces, getDecimalValue } from 'utils/numbers';
import PageLoader from 'components/pageLoader/PageLoader';


const AddMarginTicker = () => {

  const { user } = useContext(AuthContext)
  const { t } = useTranslation();
  const [tickers, setTickers] = useState([]);
  const [search, setSearch] = useState('')
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(0)
  const [loading, setLoading] = useState(true)
  const [marketCap, setMarketCap] = useState('')


  const handleSubmit = async (symbol) => {
    // e.preventDefault()
    try {
      console.log('symbol', symbol)
      await addMarginTicker(user, symbol)
      launchSuccess(t('notifications.actifs.add_margin_ticker.success'))
      // clearSelection();
      setSearch('')
    } catch (error) {
      console.error(error)
      launchError(t(`notifications.actifs.add_margin_ticker.${error?.response?.data?.error || 'add_margin_ticker_error'}`))
    }
  }

  const clearSelection = () => {
    setTickers([])
    setSelectedTicker({});
    setSearch('');
  };

  const fetchData = async () => {
    const splitted = marketCap.split('-');
    let mcMin, mcMax;
    if (splitted.length === 2) {
      mcMin = Number(splitted[0]) * Math.pow(10, 9);
      mcMax = Number(splitted[1]) * Math.pow(10, 9)
    } else {
      mcMin = Number(splitted[0].slice(0, 3)) * Math.pow(10, 9)
    }
    const data = await fetchAvailableMarginTicker(user, page, limit, search, mcMin, mcMax)
    setTickers(data)
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [page, limit, search, marketCap])

  // const handleSearchTickers = async (searchTerm) => {
  //   if (searchTerm.length > 0) {
  //     try {
  //       const fetchedTickers = await fetchAvailableMarginTicker(user, searchTerm)
  //       setTickers(fetchedTickers)
  //     } catch (e) {
  //       launchError(t('notifications.actifs.add_margin_ticker.searching'))
  //     }
  //   } else {
  //     setTickers([])
  //   }
  // }

  // useEffect(() => {
  //   const delayDebounceFn = setTimeout(() => {
  //     console.log(search)
  //     handleSearchTickers(search)
  //   }, 300)

  //   return () => clearTimeout(delayDebounceFn)
  // }, [search])

  const transformData = (data) => {
    if (data.length > 0) {
      return data.map((item, index) => {
        return {
          data: [
            <TickerImage ticker={item.symbol.split('.')[0]} placeholder={item.symbol} bold />,
            item.leverage_rate,
            formatNumberWithSpaces(getDecimalValue(item.market_cap / Math.pow(10, 9))),
            formatNumberWithSpaces(getDecimalValue(item.volume_avg / Math.pow(10, 6))),
            <button className='add_margin_ticker_button' key={item._id} onClick={() => handleSubmit(item.symbol)}>Ajouter</button>
          ],
          utils: {
            id: item.uuid
          }
        }
      })
    } else {
      return []
    }
  }


  return (
    <DashboardLayout page={t("pages_name.add_margin_ticker")}>
      <Notification />
      <div className='add_margin_ticker_header_box'>
        <h2>{t('pages.add_margin_ticker.title')}</h2>
      </div>
      {!loading ?
        (
          <div>
            <FilterComponent
              limit={true}
              withSearch={true}
              fetchLimit={limit}
              setFetchLimit={setLimit}
              search={search} setSearch={setSearch}
              useMarginTickerFilter
              marketCap={marketCap}
              setMarketCap={setMarketCap}
            />
            <Tableau
              thead={["Symbole", "Leverage rate", 'Market Cap (Mds $)', 'Volume Moyen (M $)', 'Ajouter']}
              tbody={transformData(tickers)}
              limit={limit}
              pagination={true}
              page={page}
              setPage={setPage}
              hadLink={true}
            />
          </div>
        ) : (
          <div className='pageLoaderBox'>
            <PageLoader />
          </div>
        )
      }

      {/* <form onSubmit={handleSubmit} className='add_margin_ticker_form'>
          <div className='snt_container'>
            {(!selectedTicker?.symbol) ? (
              <input
                type="text"
                placeholder='Rechercher un ticker margin disponible'
                className='snt_input'
                onChange={(e) => setSearch(e.target.value)}
              />
            ) : (
              <div className={`${selectedTicker?.symbol ? "snt_ticker_selected" : "snt_ticker_not_selected"} relative`}>
                <span onClick={() => setSelectedTicker(selectedTicker)}>
                  <p><strong>{selectedTicker.symbol}</strong></p>
                  <p>{selectedTicker?.isin}</p>
                </span>
                <div onClick={() => clearSelection()} className='snt_delete_selected_item'>x</div>
              </div>
            )}
            {(!selectedTicker?.symbol) && tickers.length > 0 && (
              <ul className='snt_tickers_list_box'>
                {tickers.map((ticker, index) => (
                  <li key={index} onClick={() => setSelectedTicker(ticker)} className="snt_ticker_not_selected">
                    <div className='add_margin_ticker_result_search'>
                      <p><strong>{ticker.symbol}</strong></p>
                      <p>{ticker?.isin}</p>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
          <input type="submit" className='add_margin_ticker_button_submit' disabled={!selectedTicker?.symbol} value="Valider" />
        </form> */}
    </DashboardLayout>
  )
}

export default AddMarginTicker