import './agoUser.css'
import { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import DashboardLayout from 'components/layout/dashboard/DashboardLayout'
import AuthContext from 'contexte/AuthContext'
import { useTranslation } from 'react-i18next';
import { fetchUserById, fetchFeaturesById, updateUser, updateUserFeatures, fetchFraudCheck, uploadUserDocument } from 'services/fetchUserById'
import PageLoader from 'components/pageLoader/PageLoader'
import { RELEASE_DATE, formatDate } from 'utils/date';
import { launchError, launchSuccess } from 'components/notifications'
import Notification from 'components/notifications/Notification'
import { getDecimalValue } from 'utils/numbers'
import { addFreeTransactionsToUser, exportPortfolioHistory, updateCertification } from 'services/userServices'
import DateRangeSelector from 'components/Filters/DateSelector/DateSelector'
import { downloadFile } from 'utils/helpers'
import { fetchAgoUserById } from 'services/agoServices'
import AgoDataArray from './AgoDataArray/AgoDataArray'

const AgoUser = () => {


  let { id } = useParams()
  const { t } = useTranslation();
  const { user, email } = useContext(AuthContext)
  let isSuperAdmin = process.env.REACT_APP_SUPERADMIN_EMAILS.split(',')?.includes(email)


  const [loading, setLoading] = useState(true)
  const [userData, setUserData] = useState({})
  const [features, setFeatures] = useState({});
  const [isEditionMode, setEditionMode] = useState(false)
  const [editionData, setEditionData] = useState({})
  const [showFullScreen, setShowFullScreen] = useState(false);
  const [newFreeTransactions, setNewFreeTransactions] = useState(0);
  const [fraudCheck, setFraudCheck] = useState({ devices: 0, cards: 0 })
  const [file, setFile] = useState(null);
  const [fileBase64, setFileBase64] = useState(null);
  const [fileType, setFileType] = useState('')

  const [exportStartingDate, setExportStartingDate] = useState({
    date1: { startDate: RELEASE_DATE },
  });
  const [exportEndingDate, setExportEndingDate] = useState({
    date1: { startDate: new Date() },
  });


  const fetchData = async () => {
    try {
      const getUserData = await fetchAgoUserById(user, id)
      setUserData(getUserData)
      // setEditionData(buildEditionData(getUserData))
      setLoading(false)
    } catch {
      console.error('error')
    }
  }

  useEffect(() => {
    fetchData()
  }, [])


  function removeUndefinedPrefix(str) {
    return str.replace(/^undefined\//, '');
  }

  const exportHistory = async () => {
    try {
      const data = await exportPortfolioHistory(user, id, exportStartingDate.date1.startDate, exportEndingDate.date1.startDate);
      downloadFile({ data, type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;', filename: `history_report_${id}.xlsx` })
    } catch (e) {
      launchError('Erreur download link')
    }
  }

  return (
    <DashboardLayout page={t("pages_name.users") + ' / ' + id}>
      <div className=''>
        <Notification />
        {
          !loading ? (
            <div className='userById_container'>
              <div className='userData_header'>
                <div className='userData_profil'>
                  <img src={`${process.env.REACT_APP_CDN_URL}/usr/pic/${userData.pic}`}
                    alt="profilepic"
                    className='user_profile_pic' />
                  <div>
                    <p className='user_profil_name'>{userData.firstName} {userData.lastName}
                      {userData.isCertified &&
                        <span>
                          <img src="/img/certified.svg" alt="certified" />
                        </span>
                      }
                      {userData.is_deleted && ' (Compte supprimé)'}
                    </p>
                    <p className='user_profil_email'>{userData.email}</p>
                  </div>
                </div>
                <div>
                </div>
              </div>
              <div className='userData_chiffres'>
                <div className='userData_kyc'>
                  <p className='user_doc_title'>KYC :</p>
                  <div className='userData_kyc_box'>
                    <div className='userData_registerinfo userData_box'>
                      <p className='userData_registerinfo_created userData_p userData_item_desc'><span className='userData_item_title'>Inscription :</span> {formatDate({ date: userData.created_at, time: true })}</p>
                      {
                        userData.sumsub_status === "VERIFIED" && (
                          <p className='userData_registerinfo_approved userData_p userData_item_desc'><span className='userData_item_title'>Vérifié :</span> {formatDate({ date: userData.kyc_date, time: true })}</p>
                        )
                      }
                    </div>
                    <div className='userData_statusinfo userData_box'>
                      <p className='userData_statusinfo_approved userData_p userData_item_desc'><span className='userData_item_title'>Sumsub :</span>{userData.sumsub_status}</p>
                      <p className='userData_statusinfo_created userData_p userData_item_desc'><span className='userData_item_title'>Alpaca :</span>{userData.alpaca_status}</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className='userData_image'>
                <p className='user_doc_title'>Documents :</p>

                {isSuperAdmin &&
                  <div className='user_document_upload'>
                    <label htmlFor='upload_doc' className='history_export_button'>Choisir un fichier</label>
                    <input id='upload_doc' type='file' className='user_file_upload' onChange={handleFileChange} />
                    {file &&
                      <>
                        <label>{file.name}</label>
                        <select className='custom-select' value={fileType} onChange={(e) => setFileType(e.target.value)}>
                          <option value=''>Type</option>
                          <option value='passport'>Passeport</option>
                          <option value='utility_bill'>Justificatif de domicile</option>
                        </select>
                        <button onClick={uploadDocument} className='history_export_button' disabled={!file || !fileType}>Envoyer le document</button>
                      </>
                    }
                  </div>
                }


                <div className='user_documents_types_container'>
                  {userData?.idDocs?.length +
                    userData?.passport_pictures?.length +
                    userData?.utility_bill_pictures?.length === 0 &&
                    <p>Aucun document</p>
                  }
                  <div>
                    {userData?.idDocs?.length > 0 && <p className='user_document_sumsub_type'>Documents Sumsub</p>}
                    <div className='userData_documents_container'>
                      {
                        userData?.idDocs?.map((item, index) =>
                        (
                          <div className='userData_documents' key={index}>
                            <img src={`data:image/png;base64,%20${item}`} alt="profil img" className='userData_doc_item' />
                          </div>
                        )
                        )
                      }
                    </div>
                  </div>
                  {['passport_pictures', 'utility_bill_pictures'].map((docType, indexType) =>
                    <div key={indexType}>
                      {userData?.[docType]?.length > 0 && <p className='user_document_type'>{docType === 'passport_pictures' ? 'Passeport' : 'Justificatif de domicile'}</p>}
                      <div className='userData_documents_container'>

                        {
                          userData?.[docType]?.map((item, index) =>
                          (
                            <div className='userData_documents' key={index}>
                              <a href={item} target='_blank'>Document {index}</a>
                            </div>
                          )
                          )
                        }
                      </div>
                    </div>
                  )}

                </div>

              </div> */}


              <div className='userData_chiffres'>
                <div className='userData_chiffres_user'>
                  <p className='user_doc_title'>User :</p>
                  <div className='userData_wallet_box'>
                    <div className='userData_box'>
                      <p className='userData_p userData_item_desc'><span className='userData_item_title'>Numero de téléphone :</span>{userData.phone}</p>
                      <p className="userData_p userData_item_desc"><span className='userData_item_title'>Date de naissance :</span> {formatDate({ date: userData.birthDate })}</p>
                      <p className="userData_p userData_item_desc userData_adresse"><span className='userData_item_title'>Adresse :</span>{userData.address}</p>
                    </div>
                    <div className='userData_box'>
                      <p className='userData_p userData_item_desc'><span className='userData_item_title'>Revenus :</span>{userData.income_amount}</p>
                      <p className='userData_p userData_item_desc'><span className='userData_item_title'>Source :</span>{userData.funding_source}</p>
                      <p className='userData_p userData_item_desc'><span className='userData_item_title'>Industrie :</span>{userData.industry}</p>
                    </div>
                  </div>
                </div>
                {/* <div className='userData_chiffres_wallet'>
                  <p className='user_doc_title'>Rapports :</p>
                  <div className='userData_wallet_box'>
                    <div className='userData_box'>
                      <p className='userData_p userData_item_desc'><span className='userData_item_title'>Valeur totale du portfolio : </span>{userData.portfolioValue} €</p>
                      <p className="userData_p userData_item_desc"><span className='userData_item_title'>Montant total de volume trade :</span>{userData.total_volume} €</p>
                      <p className="userData_p userData_item_desc"><span className='userData_item_title'>Montant total d'argent :</span>{userData.wallet} €</p>
                    </div>
                    <div className='userData_box'>
                      <p className='userData_p userData_item_desc'><span className='userData_item_title'>Nombre total de transactions effectuées : </span>{userData.total_trades}</p>
                      <p className="userData_p userData_item_desc"><span className='userData_item_title'>Panier moyen de l'utilisateur : </span>{getDecimalValue(userData.average_invest)} €</p>
                      <p className='userData_p userData_item_desc'><span className='userData_item_title'>Profit sur client (commissions) :</span>{userData.profit} €</p>
                      <p className='userData_p userData_item_desc'><span className='userData_item_title'>Playlists débloquées :</span>{userData.playlist_unlocks.count} ({userData.playlist_unlocks.expenses} €)</p>
                    </div>
                    <div className='userData_box'>
                      <p className="userData_p userData_item_desc"><span className='userData_item_title'>Gains realisés : </span>{getDecimalValue(userData.realized_pl)} €</p>
                      <p className="userData_p userData_item_desc"><span className='userData_item_title'>Montant gagné via parrainage : </span>{userData.gift_referral} €</p>
                      <p className="userData_p userData_item_desc"><span className='userData_item_title'>Montant gagné via playlist : </span>{userData.gift_playlist} €</p>
                    </div>
                  </div>
                </div> */}
              </div>

              {/* <div className='userData_chiffres'>
                <div className='userData_leverage'>
                  <p className='user_doc_title'>Leverage :</p>
                  <div className='userData_wallet_box'>
                    <div className='userData_box'>
                      <p className='userData_p userData_item_desc'><span className='userData_item_title'>Montant emprunté :</span>{userData.leverage?.borrowed_amount}</p>
                      <p className="userData_p userData_item_desc"><span className='userData_item_title'>Profit/Loss :</span> {userData.leverage?.profit_loss}</p>
                    </div>
                    <div className='userData_box'>
                      <p className='userData_p userData_item_desc'><span className='userData_item_title'>Trades réalises :</span>{userData.leverage?.trades_count}</p>
                      <p className="userData_p userData_item_desc"><span className='userData_item_title'>Gains :</span> {userData.leverage?.gains}</p>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* 
              <div className='userData_image'>
                <p className='user_doc_title'>Ibans :</p>
                <div className='userData_documents_container'>
                  {
                    userData?.ibans_pictures?.length > 0 ? (
                      userData.ibans_pictures.map((item, index) => {
                        return (
                          <div className='userData_documents' key={index}>
                            <img src={removeUndefinedPrefix(item)} alt="iban img" className='userData_doc_item'
                              onClick={() => setShowFullScreen(prevState => prevState === item ? false : item)} />
                          </div>
                        )
                      })
                    ) : (
                      <p>Aucun document</p>
                    )
                  }
                </div>
              </div> */}
              {/* {showFullScreen && (
                <div
                  className="fullscreen-overlay active"
                  onClick={() => setShowFullScreen(false)}
                >
                  <div className="fullscreen-image">
                    <img
                      className="centered-image"
                      src={showFullScreen}
                      alt="Full Screen"
                    />
                  </div>
                </div>
              )} */}

              {/* <div className='userData_free_transactions'>
                <p className='user_doc_title'>Compliance Export Portfolio History :</p>
                <div className='history_export_box'>
                  <div className='history_export_box_item'>
                    <label htmlFor='export_starting_input' className='userData_item_title'>Starting Date : </label>
                    <DateRangeSelector
                      id='export_starting_input'
                      selectedDate={exportStartingDate}
                      setSelectedDate={setExportStartingDate}
                      simpleDate
                    />
                  </div>
                  <div className='history_export_box_item'>
                    <label htmlFor='export_ending_input' className='userData_item_title'>Ending Date : </label>
                    <DateRangeSelector
                      id='export_ending_input'
                      selectedDate={exportEndingDate}
                      setSelectedDate={setExportEndingDate}
                      simpleDate
                    />
                  </div>
                  <div className='history_export_box_item'>
                    <button className='history_export_button' onClick={exportHistory}>Export</button>
                  </div>
                </div>
              </div> */}

              <AgoDataArray id={id} />
            </div>
          ) : (
            <div className='pageLoaderBox'>
              <PageLoader />
            </div>
          )
        }
      </div>
    </DashboardLayout>
  )
}

export default AgoUser