export const buildHeaders = (user) => ({
  headers: {
    "Content-Type": "application/json",
    "Authorization": "Bearer " + user
  }
})

export const API_BASE_URL = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_VERSION;

export const AGO_API_BASE_URL = process.env.REACT_APP_AGBK_BASE_URL

