import { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './queues.css'
import DashboardLayout from 'components/layout/dashboard/DashboardLayout'
import AuthContext from 'contexte/AuthContext';
import PageLoader from 'components/pageLoader/PageLoader';
import { launchError } from 'components/notifications';
import Notification from 'components/notifications/Notification';
import { formatDate } from 'utils/date';
import { fetchQueueCount, fetchQueueList, replayQueue } from 'services/queueServices';
import Tableau from 'components/DataComponents/Tableau/Tableau';
import Modal from 'components/modal/Modal';
import ScrollBar from 'components/Scrollbar/Scrollbar';

const Queues = () => {

  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [queues, setQueues] = useState([]);
  const [count, setCount] = useState([]);
  const [replayDisabled, setReplayDisabled] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalIndex, setModalIndex] = useState(-1)


  const fetchQueues = async () => {
    try {
      setLoading(true)
      const data = await fetchQueueList(
        user,
        params.name
      )
      setQueues(data.map(item => ({ ...item, MessageBody: JSON.parse(item.MessageBody) })))
      handleReplay()
    } catch (error) {
      launchError(t('notifications.queues.error.fetch'))
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  let disableTimeout
  const handleReplay = () => {
    setReplayDisabled(true)
    clearTimeout(disableTimeout)
    disableTimeout = setTimeout(() => setReplayDisabled(prev => !prev), 23000);
  }

  const fetchCount = async () => {
    try {
      setLoading(true)
      const data = await fetchQueueCount(
        user,
        params.name
      )
      setCount(data)
    } catch (error) {
      launchError(t('notifications.queues.error.count'));
    } finally {
      setLoading(false)
    }
  }

  const replay = async () => {
    console.log('Replay!!!')
    try {
      await replayQueue(user, params.name)
      setQueues([]);
      await fetchCount(user, params.name)
      await fetchQueues(user, params.name)
      handleReplay()
    } catch (error) {
      launchError(t('notifications.queues.error.replay'));
    }
  }

  useEffect(() => {
    fetchQueues()
    fetchCount()
  }, [])


  const transformDataToBody = (data) => {

    return data.map((item) =>
    ({
      data: [
        formatDate({ date: item.MessageBody?.at, time: true }),
        item.MessageBody?.event,
        item.MessageBody?.order?.symbol,
        item.MessageBody?.order?.client_order_id

      ]
    })
    )
  }

  const openModal = (index) => {
    setModalIndex(index)
    setIsModalOpen(true);
  }

  return (
    <DashboardLayout page={t("pages_name.analytics")}>
      <Notification />
      <div>
        {!loading ? (
          <div>
            <div className='externalDeposit_titleComponent'>
              <h2>Liste des trades {params.name?.toUpperCase()} rejetés</h2>
            </div>
            <p>Message dans la queue : {count}</p>
            <Tableau
              thead={["Date", "Event", "Ticker", "Client Order Id"]}
              tbody={transformDataToBody(queues)}
              hadModal
              onClickFunction={openModal}
            />

            <div className='button_replay_box'>
              <button
                className={`button_replay ${replayDisabled && 'disabled_button'}`}
                disabled={replayDisabled}
                onClick={replay}
              >Replay
                {
                  replayDisabled &&
                  <span className='spinner-button'></span>
                }
              </button>
            </div>
          </div>
        ) : (
          <div className='pageLoaderBox'>
            <PageLoader />
          </div>
        )}
        {
          isModalOpen && (
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} closeButton={true} triggerFonction={true} onMountFunction={() => { }} width={'800px'}>
              <h2 className='modal_title'>Détail de la queue</h2>
              <ScrollBar>
                <div className='queue_detail'><pre>
                  {JSON.stringify(queues[modalIndex], null, 2)}
                </pre></div>
              </ScrollBar>
            </Modal>
          )
        }
      </div>
    </DashboardLayout>
  )
}

export default Queues