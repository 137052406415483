import axios from "axios";
import { API_BASE_URL, buildHeaders } from "utils/services";


export const fetchExternalDeposit = async (user, page, limit, status, startDate, endDate) => {

    try {
        const {data} = await axios.get(`${API_BASE_URL}/deposits/external/list?startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}&status=${status}`,
            buildHeaders(user)
        )
        return data
    } catch (err) {
        console.log(err)
        return {
            error: err
        }
    }

}

export const linkToClient = async (user, deposit_id, user_uuid) => {

    try {
        const {data} = await axios.post(`${API_BASE_URL}/deposits/external/link`, {
            deposit_id,
            user_uuid
        },
        buildHeaders(user)
    )

        return data

    } catch (err) {
        console.log(err)
        throw err
    }
}

export const fetchExternalDepositUser = async (user, query) => {

    try {
        const {data} = await axios.get(`${API_BASE_URL}/users/search?search=${query}`, 
            buildHeaders(user)
        )
        return data
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const createExternalDeposit = async (user, user_uuid, amount, iban, bic) => {
    try {
        const {data} = await axios.post(`${API_BASE_URL}/deposits/external/create`, 
            {creator: user, user_uuid, amount, iban, bic},
            buildHeaders(user)
        )
        return data
    } catch (err) {
        console.log(err)
        throw err
    }
}