import axios from "axios";
import { API_BASE_URL, buildHeaders } from "utils/services";

export const fetchUsersToCall = async ({ user, page, limit, startDate, endDate, search, filterType, liquidNetWorth, ageRange, alpaca, called, noAnswer }) => {
  try {
    const searchParams = `${search !== '' ? `&value=${search}` : ''}${filterType !== 'all' ? `&filter=${filterType}` : ''}`
    let params = `?&page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}&liquidNetWorth=${liquidNetWorth}&ageRange=${ageRange}${searchParams}`
    params += `&alpaca=${alpaca}&called=${called}&noAnswer=${noAnswer}`
    const { data } = await axios.get(`${API_BASE_URL}/users/clients/call/list${params}`,
      buildHeaders(user)
    )
    return { count: data?.count, result: data?.result }
  } catch (err) {
    throw err
  }
};

export const updateUserCallLog = async (user, user_uuid, calllogs) => {
  try {
    const { data } = await axios.put(`${API_BASE_URL}/users/clients/call/update/${user_uuid}`,
      calllogs,
      buildHeaders(user)
    )
    return data
  } catch (err) {
    throw err
  }
}

export const addFreeTransactionsToUser = async (user, user_uuid, free_transactions) => {
  try {
    const { data } = await axios.post(`${API_BASE_URL}/users/clients/details/free-transactions/${user_uuid}/`,
      { free_transactions },
      buildHeaders(user)
    )
    return data
  } catch (err) {
    throw err
  }
}

export const fetchReduceFees = async (user) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/users/clients/reduced-fees`,
      buildHeaders(user)
    )
    console.log(data)
    return data
  } catch (err) {
    throw err
  }
}

export const reducedUsersFees = async (user, new_fees) => {
  try {
    const { data } = await axios.post(`${API_BASE_URL}/users/clients/reduced-fees`,
      new_fees,
      buildHeaders(user)
    )
    return data
  } catch (err) {
    throw err
  }
}

export const reducedMarginFeesToUser = async (user, user_uuid, new_fees) => {
  try {
    const { data } = await axios.post(`${API_BASE_URL}/users/clients/details/reduced-margin-fees/${user_uuid}/`,
      new_fees,
      buildHeaders(user)
    )
    return data
  } catch (err) {
    throw err
  }
}

export const exportPortfolioHistory = async (user, user_uuid, startDate, endDate) => {
  try {
    const params = `?startDate=${startDate}&endDate=${endDate}`
    const { data } = await axios.get(`${API_BASE_URL}/users/clients/${user_uuid}/history-report${params}`,
      buildHeaders(user)
    )
    return data
  } catch (err) {
    throw err
  }
}

export const updateCertification = async (user, user_uuid, isCertified) => {
  try {
    const { data } = await axios.post(`${API_BASE_URL}/users/clients/details/certification/${user_uuid}`,
      { newIsCertified: isCertified },
      buildHeaders(user)
    )
    return data
  } catch (err) {
    throw err
  }
}

export const updatePartnerStatus = async (user, user_uuid, isPartner) => {
  try {
    const { data } = await axios.post(`${API_BASE_URL}/users/clients/details/partner/${user_uuid}`,
      { newIsPartner: isPartner },
      buildHeaders(user)
    )
    return data
  } catch (err) {
    throw err
  }
}

export const updatePartnerPercentages = async (user, user_uuid, percentages) => {
  try {
    const { data } = await axios.post(`${API_BASE_URL}/users/clients/details/partner-percentages/${user_uuid}`,
      percentages,
      buildHeaders(user)
    )
    return data
  } catch (err) {
    throw err
  }
}

export const fetchDeletedUsers = async ({ user, page, limit, alpaca }) => {
  try {
    let params = `?&page=${page}&limit=${limit}&alpaca=${alpaca}`
    const { data } = await axios.get(`${API_BASE_URL}/users/clients/deleted/list${params}`,
      buildHeaders(user)
    )
    return data
  } catch (err) {
    throw err
  }
};

export const updateUserAppFeatures = async (user, user_uuid, features) => {
  try {
    const { data } = await axios.post(`${API_BASE_URL}/users/clients/${user_uuid}/features/update`,
      features,
      buildHeaders(user)
    )
    return data
  } catch (err) {
    throw err
  }
}

export const notifyUsers = async (user, notification) => {
  try {
    const { data } = await axios.post(`${API_BASE_URL}/users/notify`,
      notification,
      buildHeaders(user)
    )
    return data
  } catch (err) {
    throw err
  }
}

export const closeAccount = async (user, user_uuid, reason) => {
  try {
    const { data } = await axios.post(`${API_BASE_URL}/users/clients/${user_uuid}/close-account`,
      { reason },
      buildHeaders(user)
    )
    console.log('data', data)
    return data
  } catch (err) {
    throw err
  }
}