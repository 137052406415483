import axios from "axios";
import { API_BASE_URL, buildHeaders } from "utils/services";

export const approveRejectPendingPayout = async (user, type, withdraw_id) => {
    try {
        const { data } = await axios.post(`${API_BASE_URL}/payouts/pending/${type}`,
            { withdraw_id },
            buildHeaders(user)
        )
        return data
    } catch (error) {
        console.error(error)
        throw error
    }
}