import { useState, useEffect, useContext } from "react"
import AuthContext from "contexte/AuthContext"
import { useTranslation } from "react-i18next"
import "./reportGifts.css"
import DashboardLayout from "components/layout/dashboard/DashboardLayout"
import FilterComponent from "components/Filters/FilterComponent/FilterComponent"
import PageLoader from "components/pageLoader/PageLoader"
import Tableau from "components/DataComponents/Tableau/Tableau"
import { fetchGiftsReport } from "services/fetchUserReports"
import { formatDate, RELEASE_DATE } from "utils/date"


const ReportGifts = () => {

  const { user } = useContext(AuthContext);
  const { t } = useTranslation();

  const [gifts, setGifts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [selectedDate, setSelectedDate] = useState({
    date1: { startDate: new Date(RELEASE_DATE), endDate: new Date() },
    date2: { startDate: null, endDate: null },
  });
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const fetchData = async () => {
    try {
      const endDate = selectedDate.date1?.endDate;
      const startDate = selectedDate.date1?.startDate;
      const data = await fetchGiftsReport({ user, startDate, endDate, limit, page });
      setGifts(data);
      setLoading(false);

    } catch (e) {
      console.log(e);
      setError(true);
    }
  }

  useEffect(() => {
    fetchData()
  }, [page, limit, selectedDate.date1?.startDate, selectedDate?.date1?.endDate]
  );

  const transformDataToTbody = (data) => {

    return data.map(item => {
      return {
        data: [
          formatDate({ date: item.filled_day, month: 'long' }),
          `${item.volume_stock_gifts} (${item.stock_gifts})`,
          `${item.amount_playlist_gifts} (${item.playlist_gifts})`,
          `${item.amount_referral_gifts} (${item.referral_gifts})`,
          `${item.amount_invoice_gifts} (${item.invoice_gifts})`,
          `${item.amount_gesture_gifts} (${item.gesture_gifts})`,
          `${item.amount_sp_cashback_gifts} (${item.sp_cashback_gifts})`
        ]
      };
    });
  }

  return (
    <DashboardLayout page={t("pages_name.report_users")}>
      <div>
        {
          !loading ? (
            <div>
              <div className="flexbox">
                <h3>Volume et montant des cadeaux</h3>
                <FilterComponent
                  page={true}
                  limit={true}
                  date
                  fetchLimit={limit} setFetchLimit={setLimit}
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                />
              </div>
              <div>
                <Tableau
                  thead={['Date', 'Stocks', 'Affiliation Playlist', 'Affiliation Parrainage', 'Facture', 'Gestes commerciaux', 'SP cashback']}
                  tbody={transformDataToTbody(gifts)}
                  limit={limit}
                  page={page}
                  setPage={setPage}
                  pagination={true}
                />
              </div>
            </div>
          ) : (
            <div className='pageLoaderBox'>
              <PageLoader />
            </div>
          )
        }
      </div>
    </DashboardLayout>
  )
}

export default ReportGifts