import './mainActions.css'


const MainActions = ({ mainActionsData }) => {

    return (
        <div className='home_action_main' >
            <a className='home_action_first_box' href="/finances/payouts">
                <div >
                    <p>{mainActionsData?.payouts?.length || "0"}</p>
                    <div className='home_action_first_box_text'>
                        <span>Payouts en cours</span>
                        <img src="/img/arrow_right.svg" alt="right arrow" />
                    </div>
                </div>
            </a>
            <a className='home_action_first_box' href='/finances/external-deposit'>
                <div>
                    <p>{mainActionsData?.deposits?.length || "0"}</p>
                    <div className='home_action_first_box_text'>
                        <span>Dépôts externes</span>
                        <img src="/img/arrow_right.svg" alt="right arrow" />
                    </div>
                </div>
            </a>
            <a className='home_action_first_box' href='/finances/iban'>
                <div>
                    <p>{mainActionsData?.ibans?.length || "0"}</p>
                    <div className='home_action_first_box_text'>
                        <span>IBANs à valider</span>
                        <img src="/img/arrow_right.svg" alt="right arrow" />
                    </div>
                </div>
            </a>
            <a className='home_action_first_box' href='/finances/utility-bills'>
                <div>
                    <p>{mainActionsData?.utilityBill?.length || "0"}</p>
                    <div className='home_action_first_box_text'>
                        <span>Justificatif de domicile à valider</span>
                        <img src="/img/arrow_right.svg" alt="right arrow" />
                    </div>
                </div>
            </a>
            <a className='home_action_first_box' href='/ago/deposits'>
                <div>
                    <p>{mainActionsData?.agoDeposits?.length || "0"}</p>
                    <div className='home_action_first_box_text'>
                        <span>Dépôts externes AGO</span>
                        <img src="/img/arrow_right.svg" alt="right arrow" />
                    </div>
                </div>
            </a>
            <a className='home_action_first_box' href="/ago/payouts">
                <div >
                    <p>{mainActionsData?.agoWithdraws?.data || "0"}</p>
                    <div className='home_action_first_box_text'>
                        <span>Payouts en cours AGO</span>
                        <img src="/img/arrow_right.svg" alt="right arrow" />
                    </div>
                </div>
            </a>
        </div>
    )
}

export default MainActions