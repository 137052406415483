import { useEffect, useState, useContext } from 'react';
import AuthContext from "contexte/AuthContext"
import { useTranslation } from 'react-i18next';
import DashboardLayout from 'components/layout/dashboard/DashboardLayout'
import Notification from 'components/notifications/Notification';
import { launchError } from 'components/notifications';
import DownloaderLink from 'components/DownloaderLink/DownloaderLink';
import { downloadAgbkTransactionsMonitoringReport, fetchAgbkTransactionsMonitoringReports } from 'services/agbkReportsServices';

const TransactionsMonitoringAgbk = () => {

  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const [files, setFiles] = useState([]);

  useEffect(() => {
    const fetchReportList = async () => {
      try {
        const data = await fetchAgbkTransactionsMonitoringReports(user);
        setFiles(data);
      } catch (error) {
        launchError(t('notifications.reports.compliance.list.error'))
      }
    }

    fetchReportList();
  }, [])

  return (
    <DashboardLayout page={t("pages_name.agbk_report_compliance_transactions_monitoring")}>
      <Notification />
      <div className='externalDeposit_titleComponent'>
        <h2>{t("pages.agbk_report_compliance_transactions_monitoring.title")}</h2>
      </div>
      <div>
        <ul>
          {files.map(file => (
            <DownloaderLink
              filename={`users_wallet_${file}.xlsx`}
              fetchData={downloadAgbkTransactionsMonitoringReport}
              fetchDataParams={{ user, fileName: file }}
              type={'excel'}
              display={file}
              key={file}
            />
          ))}
        </ul>
      </div>
    </DashboardLayout>
  )
}

export default TransactionsMonitoringAgbk;