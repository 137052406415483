import React, { useState, useEffect, useContext } from 'react';
import '../KPI.css';
import AuthContext from 'contexte/AuthContext';
import DateSelector from 'components/Filters/DateSelector/DateSelector';
import { Box } from '../KpiBox/KpiBox';
import { fetchKpiDataForDates } from 'services/kpiServices';
import { MARGIN_RELEASE_DATE, RELEASE_DATE } from 'utils/date';

const KpiAgo = () => {
  const { user } = useContext(AuthContext);
  const [kpiData, setKpiData] = useState({
    selectedData: {
      ago_gains: 0,
      ago_avg_trade_per_client: 0,
      ago_unique_clients: 0,
      ago_clients_profit_loss: 0,
      ago_volumes: [],
      ago_trades_count: 0,
      deposits: 0
    },
    comparedData: {
      ago_gains: 0,
      ago_avg_trade_per_client: 0,
      ago_unique_clients: 0,
      ago_clients_profit_loss: 0,
      ago_volumes: [],
      ago_trades_count: 0,
      deposits: 0
    }
  });
  const [selectedDate, setSelectedDate] = useState({
    date1: { startDate: new Date(MARGIN_RELEASE_DATE), endDate: new Date() },
    date2: { startDate: null, endDate: null },
  });

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchKpiDataForDates(user, 'ago', selectedDate.date1, selectedDate.date2);
      setKpiData(data);
    };
    fetchData();
  }, [user, selectedDate]);

  return (

    <div>
      <div className='kpi_date'>
        <DateSelector selectedDate={selectedDate} setSelectedDate={setSelectedDate} withComparaison={true} />
      </div>
      <div className='kpi_container'>
        <Box
          title='Volume total'
          data={kpiData.selectedData.volume_total}
          comparedData={kpiData.comparedData.volume_total}
          unit='€'
          link='/finances/margin-transactions'
        />
        {
          kpiData?.selectedData?.ago_volumes?.map(({ currency, volume }) => {
            const comparedData = kpiData.comparedData?.ago_volumes?.find(({ comparedCurrency }) => comparedCurrency === currency);

            return <Box
              key={currency}
              title={`Volume ${currency}`}
              data={volume}
              comparedData={comparedData?.volume}
              link='/finances/margin-transactions'
            />
          })

        }
        <Box
          title='Gains'
          data={kpiData.selectedData.ago_gains}
          comparedData={kpiData.comparedData.ago_gains}
          unit='€'
        />
        <Box
          title='Investisseurs unique'
          data={kpiData.selectedData.ago_unique_clients}
          comparedData={kpiData.comparedData.ago_unique_clients}
        />
        <Box
          title='Clients PL'
          data={kpiData.selectedData.ago_clients_profit_loss}
          comparedData={kpiData.comparedData.ago_clients_profit_loss}
          unit='€'
        />
        <Box
          title='Trades/Client'
          data={kpiData.selectedData.ago_avg_trade_per_client}
          comparedData={kpiData.comparedData.ago_avg_trade_per_client}
        />
        <Box
          title='Nombre de Trades'
          data={kpiData.selectedData.ago_trades_count}
          comparedData={kpiData.comparedData.ago_trades_count}
        />
        <Box
          title='Dépôts'
          data={kpiData.selectedData.deposits}
          comparedData={kpiData.comparedData.deposits}
        />
      </div>
    </div>
  )
}


export default KpiAgo