import './userById.css'
import { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import DashboardLayout from 'components/layout/dashboard/DashboardLayout'
import AuthContext from 'contexte/AuthContext'
import { useTranslation } from 'react-i18next';
import { fetchUserById, fetchFeaturesById, updateUser, updateUserFeatures, fetchFraudCheck, uploadUserDocument, uploadUserRiskLevel } from 'services/fetchUserById'
import PageLoader from 'components/pageLoader/PageLoader'
import { RELEASE_DATE, formatDate } from 'utils/date';
import UserDataArray from './UserDataArray/UserDataArray'
import { launchError, launchSuccess } from 'components/notifications'
import Notification from 'components/notifications/Notification'
import { formatNumberWithSpaces, getDecimalValue } from 'utils/numbers'
import { addFreeTransactionsToUser, closeAccount, exportPortfolioHistory, reducedMarginFeesToUser, updateCertification, updatePartnerPercentages, updatePartnerStatus, updateUserAppFeatures } from 'services/userServices'
import DateRangeSelector from 'components/Filters/DateSelector/DateSelector'
import { downloadFile } from 'utils/helpers'
import Modal from 'components/modal/Modal'
import { useNavigate } from 'react-router-dom';
import { fetchPartnerCommissions, fetchPartnerKpi } from 'services/partnerServices'

const UserById = () => {


  let { id } = useParams()
  const { t } = useTranslation();
  const navigate = useNavigate()
  const { user, email } = useContext(AuthContext)
  let isSuperAdmin = process.env.REACT_APP_SUPERADMIN_EMAILS.split(',')?.includes(email)


  const [loading, setLoading] = useState(true)
  const [userData, setUserData] = useState({});
  const [partnerKpi, setPartnerKpi] = useState({});
  const [partnerCommissions, setPartnerCommissions] = useState({});
  const [partnerTimeframe, setPartnerTimeframe] = useState('all');
  const [features, setFeatures] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditionMode, setEditionMode] = useState(false)
  const [editionData, setEditionData] = useState({})
  const [showFullScreen, setShowFullScreen] = useState(false);
  const [newFreeTransactions, setNewFreeTransactions] = useState(0);
  const [fraudCheck, setFraudCheck] = useState({ devices: 0, cards: 0 })
  const [file, setFile] = useState(null);
  const [fileBase64, setFileBase64] = useState(null);
  const [fileType, setFileType] = useState('')
  const [riskLevel, setRiskLevel] = useState(userData?.risk_level);
  const [partnerPercentages, setPartnerPercentages] = useState({
    stocks: 0,
    leverage: 0,
    structured_products: 0
  });

  const [marginFeeQuantity, setMarginFeeQuantity] = useState(0);
  const [marginFeePercentage, setMarginFeePercentage] = useState(0);

  const [exportStartingDate, setExportStartingDate] = useState({
    date1: { startDate: RELEASE_DATE },
  });
  const [exportEndingDate, setExportEndingDate] = useState({
    date1: { startDate: new Date() },
  });

  const [marginOfferEndingDate, setMarginOfferEndingDate] = useState({
    date1: { startDate: new Date() },
  });


  const fetchData = async () => {
    try {
      const getUserData = await fetchUserById(user, id)
      setUserData(getUserData)
      setRiskLevel(getUserData.risk_level);
      setEditionData(buildEditionData(getUserData))
      setLoading(false)
    } catch {
      console.error('error')
    }
  }

  const fetchPartnerData = async () => {
    try {
      const kpiPartner = await fetchPartnerKpi(user, id, partnerTimeframe);
      setPartnerKpi(kpiPartner)
    } catch (error) {
      console.error(error)
    }
  }

  const fetchPartnerCommissionsData = async () => {
    try {
      const commissionsPartner = await fetchPartnerCommissions(user, id, partnerTimeframe);
      setPartnerCommissions(commissionsPartner)
    } catch (error) {
      console.error(error)
    }
  }

  const getFraudCheck = async () => {
    try {
      const data = await fetchFraudCheck(user, id)
      setFraudCheck(data)
    } catch (error) {
      launchError(t('notifications.users.userById.fraud_check.error'))
    }
  }

  const fetchFeatures = async () => {
    try {
      const getUserFeatures = await fetchFeaturesById(user, id);
      setFeatures(getUserFeatures);
    } catch (error) {
      console.error(error)
    }
  }

  const changePartner = async () => {
    try {
      const data = await updatePartnerStatus(user, id, !userData.is_partner);
      setUserData(prev => ({ ...prev, is_partner: data.is_partner, partner_percentages: data.partner_percentages }));
      setPartnerPercentages({
        stocks: 0,
        leverage: 0,
        structured_products: 0
      })
      launchSuccess(t('notifications.users.userById.partner.success'))
    } catch (error) {
      launchError(t('notifications.users.userById.partner.error'))
    }
  }

  const changePartnerPercentages = async () => {
    try {
      const isValid = Object.values(partnerPercentages).reduce((acc, value) => acc && value >= 0 && value <= 100, true);
      if (isValid) {
        const data = await updatePartnerPercentages(user, id, partnerPercentages);
        setUserData(prev => ({ ...prev, partner_percentages: data }))
        launchSuccess(t('notifications.users.userById.partner_percentages.success'))
      }
    } catch (error) {
      launchError(t('notifications.users.userById.partner_percentages.error'))
    }
  }


  const changeCertification = async () => {
    try {
      await updateCertification(user, id, !userData.isCertified);
      setUserData(prev => ({ ...prev, isCertified: !prev.isCertified }))
      launchSuccess(t('notifications.users.userById.certification.success'))
    } catch (error) {
      launchError(t('notifications.users.userById.certification.error'))
    }
  }

  useEffect(() => {
    fetchData()
    getFraudCheck()
    if (isSuperAdmin) {
      fetchFeatures()
    }
  }, [])

  useEffect(() => {
    if (userData.is_partner) {
      fetchPartnerData();
      fetchPartnerCommissionsData();
    }
  }, [userData.is_partner, partnerTimeframe])

  const handleFeatureChange = (feature, status) => {
    setFeatures(prevFeatures => ({
      ...prevFeatures,
      [feature]: status,
    }));
  };

  const handleUserFeatureChange = (feature, status) => {
    const newUserData = { ...userData };
    userData.user_features[feature] = status
    setUserData(newUserData)
  };

  function removeUndefinedPrefix(str) {
    return str.replace(/^undefined\//, '');
  }

  const buildEditionData = (user) => ({
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    phone: user.phone
  })

  const cancelEdition = () => {
    setEditionData(buildEditionData(userData));
    setEditionMode(prevState => !prevState);
  }

  const confirmEdition = async () => {
    try {
      await updateUser(user, id, editionData);
      setEditionData(buildEditionData(userData));
      setEditionMode(prevState => !prevState);
      setUserData(prevState => ({ ...prevState, ...buildEditionData(editionData) }));
      launchSuccess(t('notifications.users.userById.update.success'))
    } catch (error) {
      launchError(t('notifications.users.userById.update.error'))
    }

  }

  const confirmFeatures = async () => {
    try {
      // Create an object with the feature states
      const featuresToUpdate = {
        deposit: features.deposit === 'enabled',
        withdraw: features.withdraw === 'enabled',
        invest: features.invest === 'enabled',
      };
      // Call the API to update the user features
      await updateUserFeatures(user, id, featuresToUpdate); // Replace with your actual API call

      launchSuccess(t('notifications.users.userById.update.success'));
    } catch (error) {
      console.error('Error updating features:', error);
      launchError(t('notifications.users.userById.update.error'));
    }
  };


  const confirmUserAppFeatures = async () => {
    try {
      // Call the API to update the user features
      await updateUserAppFeatures(user, id, { ...userData.user_features, user_uuid: id });

      launchSuccess(t('notifications.users.userById.update.success'));
    } catch (error) {
      console.error('Error updating features:', error);
      launchError(t('notifications.users.userById.update.error'));
    }
  };


  const addFreeTransactions = async () => {
    try {
      const remaining = await addFreeTransactionsToUser(user, id, newFreeTransactions)
      setUserData(prev => {
        const newUserData = { ...prev };
        newUserData['free_transactions']['remaining'] = remaining;
        return newUserData;
      })
      setNewFreeTransactions(0);
      launchSuccess(t('notifications.users.userById.update.success'));
    } catch (error) {
      launchError(t('notifications.users.userById.update.error'));

    }
  }

  const addMarginReducedFees = async () => {
    try {
      if (marginFeePercentage && marginFeeQuantity && marginOfferEndingDate.date1) {
        const result = await reducedMarginFeesToUser(user, id, {
          quantity: marginFeeQuantity,
          percentage: marginFeePercentage,
          endDate: marginOfferEndingDate.date1?.startDate,
        })
        setUserData(prev => {
          const newUserData = { ...prev };
          newUserData['reduced_margin_fees'] = result;
          return newUserData;
        })
        setMarginFeePercentage(0)
        setMarginFeeQuantity(0)
        setMarginOfferEndingDate({
          date1: { startDate: new Date() },
        })
        launchSuccess(t('notifications.users.userById.update.success'));
      }
    } catch (error) {
      launchError(t('notifications.users.userById.update.error'));

    }
  }

  const exportHistory = async () => {
    try {
      const data = await exportPortfolioHistory(user, id, exportStartingDate.date1.startDate, exportEndingDate.date1.startDate);
      downloadFile({ data, type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;', filename: `history_report_${id}.xlsx` })
    } catch (e) {
      launchError('Erreur download link')
    }
  }


  const handleFreeTransactionsAmount = (e) => {
    const value = e.target.value;
    if (!value || value.match(/^\d{1,}(\.\d{0,4})?$/)) {
      setNewFreeTransactions(Number(value));
    }
  };


  const handleFileChange = (event) => {
    if (event.target.files) {
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = function () {
        setFileBase64(reader.result)
      };
      reader.onerror = function (error) {
        console.error('Error: ', error);
      };
      setFile(event.target.files[0]);
    }
  };

  const uploadDocument = async () => {
    try {
      if (fileBase64 && fileType) {
        const path = await uploadUserDocument(user, id, fileBase64, fileType);
        setUserData(prev => {
          const newUserData = { ...prev };
          const key = `${fileType}_pictures`
          const documents = [...newUserData[key]] || [];
          documents.push(path);
          newUserData[key] = documents
          return newUserData
        })
        setFileType('');
        setFileBase64(null);
        setFile(null)
        launchSuccess(t('notifications.users.userById.upload.success'))
      }
    } catch (error) {
      launchError(t('notifications.users.userById.upload.error'))
    }
  }

  const updateRiskLevel = async () => {
    try {
      const new_risk = await uploadUserRiskLevel(user, id, riskLevel);
      setUserData(prev => ({ ...prev, risk_level: new_risk }))
      launchSuccess(t('notifications.users.userById.risk_level.success'))
    } catch (error) {
      launchError(t('notifications.users.userById.risk_level.error'))
    }
  }

  const closeUserAccount = async (user_uuid, reason) => {
    try {
      setIsModalOpen(false)
      await closeAccount(user, user_uuid, reason)
      launchSuccess(t('notifications.users.userById.close_alpaca_account.success'))
      navigate('/users/list');
    } catch (error) {
      const error_code = error?.response?.data?.error === 'user_has_still_alpaca_position' ? 'open_position_error' : 'error';
      launchError(t(`notifications.users.userById.close_alpaca_account.${error_code}`))
    }
  }

  const handleAmount = (field, event) => {
    const value = event.target.value;
    if (!value || value.match(/^\d{1,}(\.\d{0,4})?$/)) {
      switch (field) {
        case 'free_transactions':
          setNewFreeTransactions(Number(value));
          break;
        case 'margin_fees_percentage':
          setMarginFeePercentage(Number(value));
          break;
        case 'margin_fees_quantity':
          setMarginFeeQuantity(Number(value));
          break;
        default:
          break;
      }
    }
  };

  const handleImageClick = () => {
    setShowFullScreen(true);
  };

  const handleCloseFullScreen = () => {
    setShowFullScreen(false);
  };

  return (
    <DashboardLayout page={`${t("pages_name.users")} / ${id}`}>
      <div className=''>
        <Notification />
        {
          !loading ? (
            <div className='userById_container'>
              <div className='userData_header'>
                <div className='userData_profil'>
                  <img src={`${process.env.REACT_APP_CDN_URL}/usr/pic/${userData.pic}`}
                    alt="profilepic"
                    className='user_profile_pic' />
                  <div>
                    <p className='user_profil_name'>{userData.firstName} {userData.lastName}
                      {userData.isCertified &&
                        <span>
                          <img src="/img/certified.svg" alt="certified" />
                        </span>
                      }
                      {userData.is_deleted && ' (Compte supprimé)'}
                    </p>
                    <p className='user_profil_email'>{userData.email}</p>
                  </div>
                </div>
                <div>
                  {isEditionMode ?
                    <div>
                      <button className='userData_update_cancel_button' onClick={cancelEdition}>Annuler</button>
                      <button className='userData_action_button' onClick={confirmEdition}>Valider</button>
                    </div>
                    :
                    <div className='user_header_action_button_box'>
                      {isSuperAdmin &&
                        <>
                          <button className='userData_action_button' onClick={() => changePartner()}>{userData.is_partner ? 'Retirer statut Partner' : 'Donner statut Partner'}</button>
                          <button className='userData_action_button' onClick={() => changeCertification()}>{userData.isCertified ? 'Dé-certifier' : 'Certifier'}</button>
                          {userData.alpaca_status === 'APPROVED' && <button className='close_alpaca_account_button' onClick={() => setIsModalOpen(true)}>Fermer le compte Alpaca</button>}
                        </>
                      }
                      <button className='userData_action_button' onClick={() => setEditionMode(prevState => !prevState)}>Modifier</button>
                    </div>
                  }
                </div>
              </div>
              {isEditionMode &&
                <div className='userData_informations'>
                  <div className='userData_registerinfo userData_box'>
                    <p className='user_doc_title'>Informations à modifier :</p>
                    <div className='userData_box'>
                      <p className='userData_p userData_item_desc'>
                        <span className='userData_item_edition'>Prénom :
                          <input
                            type='text'
                            value={editionData.firstName}
                            className='userData_edition_input'
                            onChange={(e) => setEditionData(prevState => ({ ...prevState, firstName: e.target.value }))}
                          />
                        </span>
                      </p>
                      <p className='userData_p userData_item_desc'>
                        <span className='userData_item_edition'>Nom :
                          <input
                            type='text'
                            value={editionData.lastName}
                            className='userData_edition_input'
                            onChange={(e) => setEditionData(prevState => ({ ...prevState, lastName: e.target.value }))}
                          />
                        </span>
                      </p>
                      <p className='userData_p userData_item_desc'>
                        <span className='userData_item_edition'>Adresse email :
                          <input
                            type='email'
                            value={editionData.email}
                            className='userData_edition_input'
                            onChange={(e) => setEditionData(prevState => ({ ...prevState, email: e.target.value }))}
                          />
                        </span>
                      </p>
                      <p className='userData_p userData_item_desc'>
                        <span className='userData_item_edition'>Numéro de téléphone :
                          <input
                            type='text'
                            value={editionData.phone}
                            className='userData_edition_input'
                            onChange={(e) => setEditionData(prevState => ({ ...prevState, phone: e.target.value }))}
                          />
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              }
              <div className='userData_chiffres'>
                <div className='userData_kyc'>
                  <p className='user_doc_title'>KYC :</p>
                  <div className='userData_kyc_box'>
                    <div className='userData_registerinfo userData_box'>
                      <p className='userData_registerinfo_created userData_p userData_item_desc'><span className='userData_item_title'>Inscription :</span> {formatDate({ date: userData.created_at, time: true })}</p>
                      {
                        userData.sumsub_status === "VERIFIED" && (
                          <p className='userData_registerinfo_approved userData_p userData_item_desc'><span className='userData_item_title'>Vérifié :</span> {formatDate({ date: userData.kyc_date, time: true })}</p>
                        )
                      }
                      <p className='userData_registerinfo_created userData_p userData_item_desc'><span className='userData_item_title'>Niveau de risque :</span>
                        <span className='update_risk_level_box'>
                          <select className='custom-select' value={riskLevel} onChange={(e) => setRiskLevel(e.target.value)}>
                            <option value='low'>Low</option>
                            <option value='medium'>Medium</option>
                            <option value='high'>High</option>
                          </select>
                          <button onClick={updateRiskLevel} className='history_export_button' disabled={riskLevel === userData.risk_level}>Modifier</button>
                        </span>
                      </p>
                    </div>
                    <div className='userData_statusinfo userData_box'>
                      <p className='userData_statusinfo_approved userData_p userData_item_desc'><span className='userData_item_title'>Sumsub :</span>{userData.sumsub_status}</p>
                      <p className='userData_statusinfo_created userData_p userData_item_desc'><span className='userData_item_title'>Alpaca :</span>{userData.alpaca_status}</p>
                    </div>
                  </div>
                </div>
                <div className='userData_fraud'>
                  <p className='user_doc_title'>Fraud check :</p>
                  <div className='userData_fraud_box'>
                    <div className='userData_box'>
                      <label className='label_title'>Nombre de devices utilisés : <span className='label_value'>{fraudCheck.devices}</span></label>
                      <label className='label_title'>Device multi-accounts : <span className={`label_value ${fraudCheck.is_device_multi_account ? 'multi_' : 'mono_'}account_device`}>{fraudCheck.is_device_multi_account ? 'Oui' : 'Non'}</span></label>
                      <label className='label_title'>Somme des dépôts éffectués : <span className='label_value'>{fraudCheck.deposits_sum} €</span></label>
                      <label className='label_title'>Somme des retraits éffectués : <span className='label_value'>{fraudCheck.withdraws_sum} €</span></label>
                    </div>
                    <div className='userData_box'>
                      <label className='label_title'>Nombre de CB enregistrées : <span className='label_value'>{fraudCheck.saved_cards}</span></label>
                      <label className='label_title'>Nombre de CB utilisées (5 dernière transactions): <span className='label_value'>{fraudCheck.cards_used_last_transactions_count}</span></label>
                      <label className='label_title'>Nombre de paiements par CB échoués: <span className='label_value'>{fraudCheck.failed_card_transactions_count}</span></label>
                    </div>

                  </div>
                </div>
              </div>

              <div className='userData_case'>
                <p className='user_doc_title'>Free transactions :</p>
                <div className='userData_wallet_box'>
                  <div className='userData_box'>
                    <p className='userData_p userData_item_desc'>
                      <label htmlFor='free_transactions_input' className='userData_item_title'>Nombre de transactions à offrir : </label>
                      <span className='userData_free_transactions_update_box'>
                        <input
                          id='free_transactions_input'
                          type='text'
                          value={newFreeTransactions}
                          className='userData_free_transactions_input'
                          onChange={(e) => { handleAmount('free_transactions', e) }}
                        />
                        <button className='userData_free_transactions_button' onClick={addFreeTransactions}>Ajouter</button>
                      </span>
                    </p>
                  </div>
                  {/* <div className='userData_box'> */}
                  <p className='userData_p userData_item_desc'><span className='userData_free_transactions_title'>Transactions offertes : </span>{userData.free_transactions.remaining}</p>
                  {/* </div> */}
                  <div className='userData_box'>
                    <p className='userData_p userData_item_desc'><span className='userData_free_transactions_title'>Transactions offertes en cours :</span>{userData.free_transactions.pending}</p>
                  </div>
                </div>
              </div>

              {userData.is_partner ?
                <div className='userData_case'>
                  <p className='user_doc_title'>Partners :</p>
                  <p className='partner_box_subtitle'>Rémunérations</p>
                  <div className='userData_wallet_box'>
                    <p className='userData_p userData_item_desc'><span className='userData_free_transactions_title'>Stocks : </span>{userData.partner_percentages?.stocks ?? 0} %</p>
                    <p className='userData_p userData_item_desc'><span className='userData_free_transactions_title'>Leverage : </span>{userData.partner_percentages?.leverage ?? 0} %</p>
                    <p className='userData_p userData_item_desc'><span className='userData_free_transactions_title'>Produits structurés : </span>{userData.partner_percentages?.structured_products ?? 0} %</p>
                    <p className='userData_p userData_item_desc'></p>
                  </div>
                  <div className='userData_wallet_box'>
                    <div className='userData_box'>
                      <p className='userData_p userData_item_desc'>
                        <label htmlFor='partner_stock_input' className='userData_item_title'>Stocks % : </label>
                        <span className='userData_partner_update_box'>
                          <input
                            id='partner_stock_input'
                            type='number'
                            value={partnerPercentages?.stocks}
                            className='userData_partner_input'
                            onChange={(e) => setPartnerPercentages(prev => ({ ...prev, stocks: Number(e.target.value) }))}
                          />
                        </span>
                      </p>
                    </div>
                    <div className='userData_box'>
                      <p className='userData_p userData_item_desc'>
                        <label htmlFor='partner_leverage_input' className='userData_item_title'>Leverage % : </label>
                        <span className='userData_partner_update_box'>
                          <input
                            id='partner_leverage_input'
                            type='number'
                            value={partnerPercentages?.leverage}
                            className='userData_partner_input'
                            onChange={(e) => setPartnerPercentages(prev => ({ ...prev, leverage: Number(e.target.value) }))}
                          />
                        </span>
                      </p>
                    </div>

                    <div className='userData_box'>
                      <p className='userData_p userData_item_desc'>
                        <label htmlFor='partner_structured_products_input' className='userData_item_title'>Produits structurés % : </label>
                        <span className='userData_partner_update_box'>
                          <input
                            id='partner_structured_products_input'
                            type='number'
                            value={partnerPercentages?.structured_products}
                            className='userData_partner_input'
                            onChange={(e) => setPartnerPercentages(prev => ({ ...prev, structured_products: Number(e.target.value) }))}
                          />
                        </span>
                      </p>
                    </div>

                    <button className='userData_free_transactions_button' onClick={changePartnerPercentages}>Modifier</button>

                  </div>

                  <p className='partner_box_subtitle'>KPI</p>
                  <div className='userData_wallet_box'>
                    <div className='partner_timeframe_box'>
                      <label htmlFor='partner_timeframe_select'>Timeframe</label>
                      <select
                        id='partner_timeframe_select'
                        className='custom-select'
                        onChange={(e) => setPartnerTimeframe(e.target.value)}
                        value={partnerTimeframe}
                      >
                        <option value='all'>Tout temps</option>
                        <option value='year'>Année</option>
                        <option value='month'>Mois</option>
                        <option value='week'>Semaine</option>
                        <option value='day'>Jour</option>
                      </select>
                    </div>
                  </div>
                  <div className='userData_wallet_box'>
                    <p className='userData_p userData_item_desc'><span className='userData_free_transactions_title'>Total inscrits : </span>{partnerKpi?.subscribers?.count ?? 0} {partnerKpi?.subscribers?.performance ? `(${partnerKpi?.subscribers?.performance} %)` : ''}</p>
                    <p className='userData_p userData_item_desc'><span className='userData_free_transactions_title'>Total trader : </span>{partnerKpi.traders?.count ?? 0} {partnerKpi.traders?.performance ? `(${partnerKpi.traders?.performance} %)` : ''}</p>
                    <p className='userData_p userData_item_desc'><span className='userData_free_transactions_title'>Nombre de dépôts : </span>{partnerKpi.depositors?.count ?? 0} {partnerKpi.depositors?.performance ? `(${partnerKpi.depositors?.performance} %)` : ''}</p>
                  </div>
                  <div className='userData_wallet_box'>
                    <p className='userData_p userData_item_desc'><span className='userData_free_transactions_title'>Total inscrits KYC : </span>{partnerKpi.kyc?.count ?? 0} {partnerKpi.kyc?.performance ? `(${partnerKpi.kyc?.performance} %)` : ''}</p>
                    <p className='userData_p userData_item_desc'><span className='userData_free_transactions_title'>Volume total : </span>{formatNumberWithSpaces(partnerKpi.volume?.count) ?? 0} € {partnerKpi.volume?.performance ? `(${partnerKpi.volume?.performance} %)` : ''}</p>
                    <p className='userData_p userData_item_desc'><span className='userData_free_transactions_title'>Total déposés : </span>{partnerKpi.deposits?.count ?? 0} {partnerKpi.deposits?.performance ? `(${partnerKpi.deposits?.performance} %)` : ''}</p>
                  </div>
                  <p className='partner_box_subtitle'>Commissions</p>
                  <p className='userData_p userData_item_desc'><span className='userData_free_transactions_title'>Commissions totales : </span>{partnerKpi.commissions?.count ?? 0} €</p>
                  {/* </div> */}
                  <div className='userData_wallet_box'>
                    <p className='userData_p userData_item_desc'><span className='userData_free_transactions_title'>Stocks : </span>{partnerCommissions?.stocks} €</p>
                    <p className='userData_p userData_item_desc'><span className='userData_free_transactions_title'>Playlist : </span>{partnerCommissions?.playlist} €</p>
                  </div>
                  <div className='userData_wallet_box'>
                    <p className='userData_p userData_item_desc'><span className='userData_free_transactions_title'>Produits structurés : </span>{partnerCommissions?.structured_products} €</p>
                    <p className='userData_p userData_item_desc'><span className='userData_free_transactions_title'>Margin : </span>{partnerCommissions?.margin} €</p>
                  </div>
                </div>
                : null}

              <div className='userData_case'>
                <p className='user_doc_title'>Margin transactions :</p>
                {userData.reduced_margin_fees?.endDate &&
                  <div className='userData_wallet_box'>
                    <p className='userData_p userData_item_desc'><span className='userData_free_transactions_title'>Transactions restantes : </span>{userData.reduced_margin_fees?.remaining || 0}</p>
                    <p className='userData_p userData_item_desc'><span className='userData_free_transactions_title'>Date de fin actuel : </span>{userData.reduced_margin_fees?.endDate ? formatDate({ date: userData.reduced_margin_fees?.endDate, month: 'long' }) : ''}</p>
                    <p className='userData_p userData_item_desc'><span className='userData_free_transactions_title'>Réduction actuelle : </span>{userData.reduced_margin_fees?.percentage || 0} %</p>
                    <p className='userData_p userData_item_desc'></p>
                  </div>
                }
                <div className='userData_wallet_box'>
                  <div className='userData_box'>
                    <p className='userData_p userData_item_desc'>
                      <label htmlFor='free_transactions_input' className='userData_item_title'>Nombre de transactions tarif réduit : </label>
                      <span className='userData_free_transactions_update_box'>
                        <input
                          id='free_transactions_input'
                          type='number'
                          value={marginFeeQuantity}
                          className='userData_free_transactions_input'
                          onChange={(e) => handleAmount('margin_fees_quantity', e)}
                        />
                      </span>
                    </p>
                  </div>

                  <div className='userData_box'>
                    {/* <p className='userData_p userData_item_desc'> */}
                    <div className='userData_p userData_item_desc'>
                      <label htmlFor='free_transactions_input' className='userData_item_title'>Date de fin : </label>
                      <span className='userData_free_transactions_update_box'>
                        <DateRangeSelector
                          selectedDate={marginOfferEndingDate}
                          setSelectedDate={setMarginOfferEndingDate}
                          simpleDate
                        />
                      </span>
                    </div>
                    {/* </p> */}
                  </div>

                  <div className='userData_box'>
                    <p className='userData_p userData_item_desc'>
                      <label htmlFor='free_transactions_input' className='userData_item_title'>% de réduction : </label>
                      <span className='userData_free_transactions_update_box'>
                        <input
                          id='free_transactions_input'
                          type='number'
                          value={marginFeePercentage}
                          className='userData_free_transactions_input'
                          onChange={(e) => handleAmount('margin_fees_percentage', e)}
                        />
                      </span>
                    </p>
                  </div>

                  <button className='userData_free_transactions_button' onClick={addMarginReducedFees}>Ajouter</button>

                </div>
              </div>

              {isSuperAdmin &&
                <div className='userData_case'>
                  <p className='user_doc_title'>User Features :</p>
                  <div className='userData_features'>
                    <div className='userData_featuresinfo userData_box'>
                      <table>
                        <thead>
                          <tr>
                            <th>Features</th>
                            <th className="center">Enabled</th>
                            <th className="center">Disabled</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.entries(features).map(([feature, status]) => (
                            <tr key={feature}>
                              <td>{feature.toUpperCase()}</td>
                              <td className="center">
                                <input
                                  type="radio"
                                  name={feature}
                                  value="enabled"
                                  checked={status === 'enabled'}
                                  onChange={() => handleFeatureChange(feature, 'enabled')}
                                />
                              </td>
                              <td className="center">
                                <input
                                  type="radio"
                                  name={feature}
                                  value="disabled"
                                  checked={status === 'disabled'}
                                  onChange={() => handleFeatureChange(feature, 'disabled')}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <button className='userData_update_features' onClick={confirmFeatures}>Valider</button>
                    </div>

                    <div className='userData_featuresinfo userData_box'>
                      <table>
                        <thead>
                          <tr>
                            <th>User Features</th>
                            <th className="center">Enabled</th>
                            <th className="center">Disabled</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.entries(userData.user_features)?.map(([feature, status]) => (
                            <tr key={feature}>
                              <td>{feature.toUpperCase()}</td>
                              <td className="center">
                                <input
                                  type="radio"
                                  name={feature}
                                  value={true}
                                  checked={status}
                                  onChange={() => handleUserFeatureChange(feature, !status)}
                                />
                              </td>
                              <td className="center">
                                <input
                                  type="radio"
                                  name={feature}
                                  value={false}
                                  checked={!status}
                                  onChange={() => handleUserFeatureChange(feature, !status)}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <button className='userData_update_features' onClick={confirmUserAppFeatures}>Valider</button>
                    </div>
                  </div>
                </div>}


              <div className='userData_image'>
                <p className='user_doc_title'>Documents :</p>
                <div className='user_document_upload'>
                  <label htmlFor='upload_doc' className='history_export_button'>Choisir un fichier</label>
                  <input id='upload_doc' type='file' className='user_file_upload' onChange={handleFileChange} />
                  {file &&
                    <>
                      <label>{file.name}</label>
                      <select className='custom-select' value={fileType} onChange={(e) => setFileType(e.target.value)}>
                        <option value=''>Type</option>
                        <option value='passport'>Passeport</option>
                        <option value='utility_bill'>Justificatif de domicile</option>
                      </select>
                      <button onClick={uploadDocument} className='history_export_button' disabled={!file || !fileType}>Envoyer le document</button>
                    </>
                  }
                </div>

                <div className='user_documents_types_container'>
                  {userData?.idDocs?.length +
                    userData?.passport_pictures?.length +
                    userData?.utility_bill_pictures?.length === 0 &&
                    <p>Aucun document</p>
                  }
                  <div>
                    {userData?.idDocs?.length > 0 && <p className='user_document_sumsub_type'>Documents Sumsub</p>}
                    <div className='userData_documents_container'>
                      {
                        userData?.idDocs?.map((item, index) =>
                        (
                          <div className='userData_documents' key={index}>
                            <img src={`data:image/png;base64,%20${item}`} alt="profil img" className='userData_doc_item' />
                          </div>
                        )
                        )
                      }
                    </div>
                  </div>
                  {['passport_pictures', 'utility_bill_pictures'].map((docType, indexType) =>
                    <div key={indexType}>
                      {userData?.[docType]?.length > 0 && <p className='user_document_type'>{docType === 'passport_pictures' ? 'Passeport' : 'Justificatif de domicile'}</p>}
                      <div className='userData_documents_container'>

                        {
                          userData?.[docType]?.map((item, index) =>
                          (
                            <div className='userData_documents' key={index}>
                              <a href={item} target='_blank'>Document {index}</a>
                            </div>
                          )
                          )
                        }
                      </div>
                    </div>
                  )}

                </div>

              </div>
              <div className='userData_chiffres'>
                <div className='userData_chiffres_user'>
                  <p className='user_doc_title'>User :</p>
                  <div className='userData_wallet_box'>
                    <div className='userData_box'>
                      <p className='userData_p userData_item_desc'><span className='userData_item_title'>Numero de téléphone :</span>{userData.phone}</p>
                      <p className="userData_p userData_item_desc"><span className='userData_item_title'>Date de naissance :</span> {formatDate({ date: userData.birthDate })}</p>
                      <p className="userData_p userData_item_desc userData_adresse"><span className='userData_item_title'>Adresse :</span>{userData.address}</p>
                    </div>
                    <div className='userData_box'>
                      <p className='userData_p userData_item_desc'><span className='userData_item_title'>Revenus :</span>{userData.income_amount}</p>
                      <p className='userData_p userData_item_desc'><span className='userData_item_title'>Source :</span>{userData.funding_source}</p>
                      <p className='userData_p userData_item_desc'><span className='userData_item_title'>Industrie :</span>{userData.industry}</p>
                    </div>
                  </div>
                </div>
                <div className='userData_chiffres_wallet'>
                  <p className='user_doc_title'>Rapports :</p>
                  <div className='userData_wallet_box'>
                    <div className='userData_box'>
                      <p className='userData_p userData_item_desc'><span className='userData_item_title'>Valeur totale du portfolio : </span>{userData.portfolioValue} €</p>
                      <p className="userData_p userData_item_desc"><span className='userData_item_title'>Montant total de volume trade :</span>{userData.total_volume} €</p>
                      <p className="userData_p userData_item_desc"><span className='userData_item_title'>Montant total d'argent :</span>{userData.wallet} €</p>
                    </div>
                    <div className='userData_box'>
                      <p className='userData_p userData_item_desc'><span className='userData_item_title'>Nombre total de transactions effectuées : </span>{userData.total_trades}</p>
                      <p className="userData_p userData_item_desc"><span className='userData_item_title'>Panier moyen de l'utilisateur : </span>{getDecimalValue(userData.average_invest)} €</p>
                      <p className='userData_p userData_item_desc'><span className='userData_item_title'>Profit sur client (commissions) :</span>{userData.profit} €</p>
                      <p className='userData_p userData_item_desc'><span className='userData_item_title'>Playlists débloquées :</span>{userData.playlist_unlocks.count} ({userData.playlist_unlocks.expenses} €)</p>
                    </div>
                    <div className='userData_box'>
                      <p className="userData_p userData_item_desc"><span className='userData_item_title'>Gains realisés : </span>{getDecimalValue(userData.realized_pl)} €</p>
                      <p className="userData_p userData_item_desc"><span className='userData_item_title'>Montant gagné via parrainage : </span>{userData.gift_referral} €</p>
                      <p className="userData_p userData_item_desc"><span className='userData_item_title'>Montant gagné via playlist : </span>{userData.gift_playlist} €</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className='userData_chiffres'>
                <div className='userData_leverage'>
                  <p className='user_doc_title'>Leverage :</p>
                  <div className='userData_wallet_box'>
                    <div className='userData_box'>
                      <p className='userData_p userData_item_desc'><span className='userData_item_title'>Volume :</span>{userData.leverage?.volume} €</p>
                      <p className='userData_p userData_item_desc'><span className='userData_item_title'>Montant emprunté :</span>{userData.leverage?.borrowed_amount} €</p>
                      <p className="userData_p userData_item_desc"><span className='userData_item_title'>Profit/Loss :</span> {userData.leverage?.profit_loss} €</p>
                    </div>
                    <div className='userData_box'>
                      <p className='userData_p userData_item_desc'><span className='userData_item_title'>Trades réalises :</span>{userData.leverage?.trades_count}</p>
                      <p className="userData_p userData_item_desc"><span className='userData_item_title'>Gains :</span> {userData.leverage?.gains} €</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className='userData_image'>
                <p className='user_doc_title'>Ibans :</p>
                <div className='userData_documents_container'>
                  {
                    userData?.ibans_pictures?.length > 0 ? (
                      userData.ibans_pictures.map((item, index) => {
                        return (
                          <div className='userData_documents' key={index}>
                            <img src={removeUndefinedPrefix(item)} alt="iban img" className='userData_doc_item'
                              onClick={() => setShowFullScreen(prevState => prevState === item ? false : item)} />
                          </div>
                        )
                      })
                    ) : (
                      <p>Aucun document</p>
                    )
                  }
                </div>
              </div>
              {showFullScreen && (
                <div
                  className="fullscreen-overlay active"
                  onClick={() => setShowFullScreen(false)}
                >
                  <div className="fullscreen-image">
                    <img
                      className="centered-image"
                      src={showFullScreen}
                      alt="Full Screen"
                    />
                  </div>
                </div>
              )}

              <div className='userData_free_transactions'>
                <p className='user_doc_title'>Compliance Export Portfolio History :</p>
                <div className='history_export_box'>
                  <div className='history_export_box_item'>
                    <label htmlFor='export_starting_input' className='userData_item_title'>Starting Date : </label>
                    <DateRangeSelector
                      id='export_starting_input'
                      selectedDate={exportStartingDate}
                      setSelectedDate={setExportStartingDate}
                      simpleDate
                    />
                  </div>
                  <div className='history_export_box_item'>
                    <label htmlFor='export_ending_input' className='userData_item_title'>Ending Date : </label>
                    <DateRangeSelector
                      id='export_ending_input'
                      selectedDate={exportEndingDate}
                      setSelectedDate={setExportEndingDate}
                      simpleDate
                    />
                  </div>
                  <div className='history_export_box_item'>
                    <button className='history_export_button' onClick={exportHistory}>Export</button>
                  </div>
                </div>
              </div>

              <UserDataArray id={id} />
              <ModalCloseAccount
                isModalOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                accountToClose={userData}
                closeAccount={closeUserAccount}

              />
            </div>
          ) : (
            <div className='pageLoaderBox'>
              <PageLoader />
            </div>
          )
        }
      </div>
    </DashboardLayout>
  )
}

const ModalCloseAccount = ({ isModalOpen, onClose, accountToClose, closeAccount }) => {

  const { t } = useTranslation()

  const { firstName, lastName, uuid } = accountToClose;

  const [reason, setReason] = useState('');

  const close = () => {
    closeAccount(uuid, reason);
    setReason('')
  }

  return (
    <Modal isOpen={isModalOpen} onClose={onClose} closeButton={false} >
      <h2 className='modal-playlist_title'>Clôturer le compte Alpaca</h2>
      <div className="close_alpaca_account_modal_box">


        <div className='warning_deleted'>
          <img src="https://assets-global.website-files.com/612cdb668b06dbb2a51f99ee/651e6e6668d310d564622c79_warning_img.png" alt="" width={25} />
          <span>Êtes-vous sûr de vouloir clôturer le compte Alpaca
            de l'utilisateur <strong>{firstName} {lastName}</strong> ?
          </span>
        </div>
        <select className="custom-select close_alpaca_account_modal_reason_select" onChange={(e) => setReason(e.target.value)} value={reason}>
          <option value={''}>Choisissez une raison</option>
          <option value={'fee'}>Frais trop élevés</option>
          <option value={'complexity'}>L'application est trop compliquée</option>
          <option value={'lost'}>L'utilisateur a perdu de l'argent</option>
          <option value={'better'}>L'utilisateur a trouvé une meilleure app</option>
          <option value={'invest'}>L'utilisateur ne souhaite plus investir</option>
          <option value={'other'}>Autre</option>
        </select>
      </div>
      <hr className='modal_hr_button' />
      <div className='close_alpaca_account_modal_button_div'>
        <button className='close_alpaca_account_close_modal_button' onClick={onClose}>{t("pages.payouts.buttons.close")}</button>
        <button className='close_alpaca_account_validate_button' onClick={close} disabled={!reason}>Clôturer</button>
      </div>
    </Modal>

  )
}

export default UserById