import { useEffect, useState, useContext } from 'react';
import { downloadAgbkEconomicProfileReport, fetchAgbkEconomicProfileReports } from 'services/agbkReportsServices';
import AuthContext from "contexte/AuthContext"
import { useTranslation } from 'react-i18next';
import DashboardLayout from 'components/layout/dashboard/DashboardLayout'
import Notification from 'components/notifications/Notification';
import { launchError } from 'components/notifications';
import DownloaderLink from 'components/DownloaderLink/DownloaderLink';
import './economicProfileAgbk.css'

const EconomicProfileAgbk = () => {

  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const [files, setFiles] = useState([]);

  useEffect(() => {
    const fetchReportList = async () => {
      try {
        const data = await fetchAgbkEconomicProfileReports(user);
        console.log('data', data);
        setFiles(data);
      } catch (error) {
        launchError(t('notifications.reports.compliance.list.error'))
      }
    }

    fetchReportList();
  }, [])

  return (
    <DashboardLayout page={t(`pages_name.agbk_report_compliance_economic_profile`)}>
      <Notification />
      <div className='economicProfile_titleComponent'>
        <h2>{t(`pages.agbk_economic_profile.title`)}</h2>
        {/* <FilterComponent
          date={true}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          maxDate={yesterday}
        /> */}
      </div>
      <div>
        <ul>
          {files.map(file => (
            <DownloaderLink
              filename={`economic_profile_${file}.xlsx`}
              fetchData={downloadAgbkEconomicProfileReport}
              fetchDataParams={{ user, fileName: file }}
              type={'excel'}
              display={file}
              key={file}
            />
          ))}
        </ul>
      </div>
    </DashboardLayout>
  )
}

export default EconomicProfileAgbk;