import React, { useState, forwardRef, useRef } from 'react';
import './DateSelector.css';
import DatePicker, { CalendarContainer } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, isSameDay, endOfDay, startOfDay, formatISO } from 'date-fns';
// import { isSameDay, format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { RELEASE_DATE } from 'utils/date';
const DATE_FORMAT = 'dd MMMM yyyy';

const DateRangeSelector = ({ selectedDate, setSelectedDate, withComparaison, simpleDate, maxDate, minDate, showTime, }) => {
    const [startDate1, setStartDate1] = useState(selectedDate.date1.startDate);
    const [endDate1, setEndDate1] = useState(selectedDate.date1?.endDate || null);
    const [startDate2, setStartDate2] = useState(selectedDate.date2?.startDate || null);
    const [endDate2, setEndDate2] = useState(selectedDate.date2?.endDate || null);

    const mainDatePickerRef = useRef();
    const comparisonDatePickerRef = useRef();

    const handleDateChange1 = (dates) => {
        let start, end;
        if (simpleDate) {
            start = dates;
        } else {
            ([start, end] = dates);
        }

        if (!start) {
            setStartDate1(null);
            setEndDate1(null);
            setSelectedDate((prevSelectedDate) => ({
                ...prevSelectedDate,
                date1: { startDate: null, endDate: null },
            }));

        } else if (!end) {

            setStartDate1(start);
            setEndDate1(null);
            setSelectedDate((prevSelectedDate) => ({
                ...prevSelectedDate,
                date1: { startDate: start, endDate: null },
            }));

        } else {
            setStartDate1(start);
            setEndDate1(end);
            setSelectedDate((prevSelectedDate) => ({
                ...prevSelectedDate,
                date1: { startDate: start, endDate: end },
            }));

        }
    };

    const handleDateChange2 = (dates) => {
        let start, end;
        if (simpleDate) {
            start = dates;
        } else {
            ([start, end] = dates);
        }

        if (!start) {
            setStartDate2(null);
            setEndDate2(null);
            setSelectedDate((prevSelectedDate) => ({
                ...prevSelectedDate,
                date2: { startDate: null, endDate: null },
            }));

        } else if (!end) {
            setStartDate2(start);
            setEndDate2(null);
            setSelectedDate((prevSelectedDate) => ({
                ...prevSelectedDate,
                date2: { startDate: start, endDate: null },
            }));

        } else {
            setStartDate2(start);
            setEndDate2(end);
            setSelectedDate((prevSelectedDate) => ({
                ...prevSelectedDate,
                date2: { startDate: start, endDate: end },
            }));

        }
    };

    const formatDateLabel = (date, startDate, endDate) => {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1)
        const today = new Date();
        const releaseDate = new Date(RELEASE_DATE);

        if (!startDate && !endDate) {
            return 'Sélectionner une comparaison';
        } else if (startDate && !endDate) {
            if (isSameDay(date, yesterday)) {
                return "Hier";
            }
            if (isSameDay(date, today)) {
                return "Aujourd'hui" + (showTime ? ` à ${format(date, 'HH:mm', { locale: fr })}` : '');
            }
            return format(date, showTime ? "dd MMMM yyyy HH:mm" : "dd MMMM yyyy", { locale: fr });
        } else if (isSameDay(startDate, endDate)) {
            return formatSingleDate(startDate)
        } else {
            return `${formatSingleDate(startDate)} - ${formatSingleDate(endDate)}`
        }
    };

    const formatSingleDate = (date) => {
        const today = new Date();
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        if (isSameDay(date, today)) {
            return "Aujourd'hui";
        }
        if (isSameDay(date, yesterday)) {
            return "Hier";
        }
        if (isSameDay(date, RELEASE_DATE)) {
            return "Depuis le début";
        }
        if (showTime) {
            return formatISO(date, { representation: 'time' })
        }
        return format(date, showTime ? "dd MMMM yyyy HH:mm" : "dd MMMM yyyy", { locale: fr })
    }


    const formatDateLabel2 = (date, startDate, endDate) => {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1)
        const releaseDate = new Date(RELEASE_DATE);

        if (!startDate && !endDate) {
            return 'Sélectionner une comparaison';
        } else if (startDate && !endDate) {
            return format(date, "dd MMMM yyyy", { locale: fr });
        } else {
            if (isSameDay(date, yesterday)) {
                return "Hier";
            }

            if (isSameDay(date, releaseDate)) {
                return "Depuis le début";
            }

            if (isSameDay(startDate, endDate)) {
                return format(startDate, "dd MMMM yyyy", { locale: fr })
            }

            return `${format(startDate, "dd MMMM yyyy", { locale: fr })} - ${format(endDate, "dd MMMM yyyy", { locale: fr })}`;

        }
    };

    const CustomInput = forwardRef(({ date, startDate, endDate, ...rest }, ref) => (
        <button className="custom-input" ref={ref} {...rest}>
            {formatDateLabel(date, startDate, endDate)}
        </button>
    )
    );

    const CustomInput2 = forwardRef(({ date, startDate, endDate, ...rest }, ref) => (
        <button className="custom-input" ref={ref} {...rest}>
            {formatDateLabel2(date, startDate, endDate)}
        </button>
    ));

    const setAllTime = () => {
        console.log('setAllTime called')
        const todayOrMaxDate = maxDate ? endOfDay(new Date(maxDate)) : endOfDay(new Date());
        const releaseDate = new Date(RELEASE_DATE);

        setStartDate1(releaseDate);
        if (simpleDate) {
            setEndDate1(null);
            console.log('inside simpleDate cond')
            setSelectedDate((prevSelectedDate) => ({
                ...prevSelectedDate,
                date1: { startDate: releaseDate, endDate: null },
            }));
        } else {
            setEndDate1(todayOrMaxDate);
            setSelectedDate((prevSelectedDate) => ({
                ...prevSelectedDate,
                date1: { startDate: releaseDate, endDate: todayOrMaxDate },
            }));
        }

        mainDatePickerRef.current.setOpen(false);
    }

    const setAllTime2 = () => {

        const today = new Date();
        const releaseDate = new Date(RELEASE_DATE);

        setStartDate2(releaseDate);
        setEndDate2(today);
        setSelectedDate((prevSelectedDate) => ({
            ...prevSelectedDate,
            date2: { startDate: releaseDate, endDate: today },
        }));
        comparisonDatePickerRef.current.setOpen(false);
    }

    const setTodayOrMaxDate = () => {
        const todayOrMaxDate = maxDate ? new Date(maxDate) : new Date();

        setStartDate1(startOfDay(todayOrMaxDate));
        setEndDate1(todayOrMaxDate);
        setSelectedDate((prevSelectedDate) => ({
            ...prevSelectedDate,
            date1: { startDate: startOfDay(todayOrMaxDate), endDate: todayOrMaxDate },
        }));
        mainDatePickerRef.current.setOpen(false);
    }

    const setYesterday = () => {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);


        setStartDate2(startOfDay(yesterday));
        setEndDate2(endOfDay(yesterday));
        setSelectedDate((prevSelectedDate) => ({
            ...prevSelectedDate,
            date2: { startDate: endOfDay(yesterday), endDate: endOfDay(yesterday) },
        }));
        comparisonDatePickerRef.current.setOpen(false);
    }


    const MyContainer = ({ children }) => {
        let label = "Aujourd'hui";
        if (maxDate) label = formatSingleDate(maxDate);
        return (
            <div style={{ backgroundColor: "#ffffff" }} className='white'>
                <CalendarContainer className='white' style={{ backgroundColor: "#ffffff" }}>
                    <div className='header_selectDate'>
                        <button onClick={setAllTime}>Depuis le début</button>
                        <button onClick={setTodayOrMaxDate}>{label}</button>
                    </div>
                    <div style={{ position: "relative" }}>{children}</div>
                </CalendarContainer>
            </div>
        );
    };

    const MyContainer2 = ({ children }) => {
        return (
            <div style={{ backgroundColor: "#ffffff" }} className='white'>
                <CalendarContainer className='white' style={{ backgroundColor: "#ffffff" }}>
                    <div className='header_selectDate'>
                        <button onClick={setAllTime2}>Depuis le début</button>
                        <button onClick={setYesterday}>Hier</button>
                    </div>
                    <div style={{ position: "relative" }}>{children}</div>
                </CalendarContainer>
            </div>
        );
    };

    return (
        <div className="date-selector">
            <div className="date-picker-container">
                <DatePicker
                    ref={mainDatePickerRef}
                    selected={startDate1}
                    onChange={handleDateChange1}
                    startDate={startDate1}
                    endDate={endDate1 || false}
                    selectsRange={!simpleDate}
                    dateFormat={showTime ? 'dd/MM/yyyy hh:mm' : 'dd/MM/yyyy'}
                    placeholderText="Sélectionner une date"
                    customInput={<CustomInput date={startDate1} startDate={startDate1} endDate={endDate1} />}
                    withPortal
                    locale={fr}
                    calendarContainer={MyContainer}
                    allowSameDay={true}
                    maxDate={maxDate || false}
                    minDate={minDate || false}
                    fixedHeight
                    showTimeInput={showTime || false}
                    timeInputLabel=''
                />
                {
                    withComparaison ? (
                        <div className='date-picker-container'>

                            <p className='date_compared_text'>comparé à</p>
                            <DatePicker
                                ref={comparisonDatePickerRef}
                                selected={startDate2}
                                onChange={handleDateChange2}
                                startDate={startDate2}
                                endDate={endDate2}
                                selectsRange={!simpleDate}
                                dateFormat="dd/MM/yyyy"
                                placeholderText="Sélectionner une comparaison"
                                customInput={<CustomInput2 date={startDate2} startDate={startDate2} endDate={maxDate || endDate2} />}
                                withPortal
                                locale={fr}
                                calendarContainer={MyContainer2}
                                allowSameDay={true}
                                maxDate={maxDate || false}
                                minDate={minDate || false}
                                showTimeSelect={showTime || false}

                            />
                        </div>
                    ) : (<></>)
                }
            </div>
        </div>
    );
};

export default DateRangeSelector;
