import { useState, useEffect, useContext } from 'react'
import AuthContext from 'contexte/AuthContext'
import './ManualPayout.css'
import Notification from 'components/notifications/Notification'
import { launchError, launchSuccess } from 'components/notifications'
import { useTranslation } from 'react-i18next';
import DashboardLayout from 'components/layout/dashboard/DashboardLayout'
import { searchUsers } from 'services/fetchUsers'
import { fetchUserIbans } from 'services/ibanServices'
import SearchUser from 'components/SearchUser/SearchUser'
import { initiateManualPayout, verifyCodeManualPayout } from 'services/fetchPayouts'
import ValidationModal from 'components/ValidationModal/ValidationModal'


const ManualPayout = () => {

  const { user } = useContext(AuthContext)
  const { t } = useTranslation();
  const [amount, setAmount] = useState(0)
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [userIbans, setUserIbans] = useState([]);
  const [selectedIban, setSelectedIban] = useState({});
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [customIban, setCustomIban] = useState('');
  const [fees, setFees] = useState(false);
  const [customFees, setCustomFees] = useState(false);
  const [revolutCustomFees, setRevolutCustomFees] = useState(0);
  const [goliathsCustomFees, setGoliathsCustomFees] = useState(0);
  const [code, setCode] = useState('');

  const clearForm = () => {
    setUserIbans([])
    setSelectedUser({})
    setAmount(0)
    setUsers([])
    setCustomIban('')
    setCode('')
    setFees(false)
    setCustomFees(false)
    setGoliathsCustomFees(0)
    setRevolutCustomFees(0)
  }

  useEffect(() => {
    const fetchData = async () => {
      const userIbans = await fetchUserIbans(user, selectedUser.uuid);
      setUserIbans(userIbans);
    }
    if (selectedUser.uuid)
      fetchData()
  }, [selectedUser.uuid])

  useEffect(() => {
    const valid = (selectedIban.id || customIban) &&
      !isNaN(amount) && amount > 0 &&
      selectedUser.uuid;
    setIsValid(valid)
  }, [selectedIban.id, customIban, selectedUser.uuid, amount])

  const handleInitiate = async (e) => {
    try {
      e.preventDefault()
      await initiateManualPayout(user, selectedUser.uuid, (selectedIban?.id || ''), (selectedIban?.iban || customIban), amount, fees, goliathsCustomFees, revolutCustomFees);
      setIsModalOpen(true);
    } catch (e) {
      launchError(t('notifications.payouts.manual_payout.initiate.error'))
    }
  }

  const handleCustomIban = (e) => {
    setCustomIban(e.target.value);
    setSelectedIban({})
  }

  const handleSelectedIban = (iban) => {
    setSelectedIban(prevState => prevState.id === iban.id ? {} : iban);
    setCustomIban('')
  }

  const formatIBAN = (iban) => {
    const ibanSansEspaces = iban.replace(/\s/g, '');
    const ibanFormate = ibanSansEspaces.replace(/(.{4})/g, '$1 ');
    return ibanFormate.trim();
  }

  const handleSubmitCode = async (e) => {
    e.preventDefault()
    try {
      await verifyCodeManualPayout(user, code)
      setIsModalOpen(false)
      launchSuccess(t('notifications.payouts.manual_payout.verify.success'))
      clearForm()
    } catch (e) {
      launchError(t('notifications.payouts.manual_payout.verify.error'))
    }
  }

  const handleAmount = (field, event) => {
    const value = event.target.value;
    if (!value || value.match(/^\d{1,}(\.\d{0,4})?$/)) {
      switch (field) {
        case 'amount':
          setAmount(value);
          break;
        case 'goliathsCustomFees':
          setGoliathsCustomFees(value);
          break;
        case 'revolutCustomFees':
          setRevolutCustomFees(value);
          break;
      }
    } else {
      launchError(t('notifications.payouts.manual_payout.verify.not_a_number'))
    }
  };

  const handleSearchUsers = async (search) => {
    if (search.length > 0) {
      try {
        setLoading(true)
        const fetchUsers = await searchUsers(user, search)
        setUsers(fetchUsers)
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    } else {
      setUsers([])
    }
  }

  const handleFees = () => {
    setFees(prev => !prev);
    setCustomFees(false)
  }

  const handleCustomFees = () => {
    setCustomFees(prev => !prev);
    setFees(false)
  }

  const selectUser = (newUser) => {
    setSelectedUser(newUser);
    if (!newUser.uuid) {
      setUserIbans([]);
    }
  }


  return (
    <DashboardLayout page={t("pages_name.alpaca")}>
      <div>
        <Notification />
        <div>
          <div className='newGift_titleComponent'>
            <h2>Nouveau virement</h2>
          </div>
          <div>
            <form onSubmit={handleInitiate} className='payout-details'>
              <SearchUser
                id='user-input'
                selectUser={selectUser}
                selectedUser={selectedUser}
                users={users}
                handleSearch={handleSearchUsers}
                label={'Rechercher un utilisateur'}
              />
              <div>
                {selectedUser.uuid && userIbans.length > 0 && (
                  <ul>
                    {userIbans.map((iban, index) => {
                      return (
                        <li key={index} onClick={() => handleSelectedIban(iban)}
                          className={`${selectedIban.id === iban.id ? "manual_payout_iban_selected" : "manual_payout_iban_not_selected"}`}>
                          <div>
                            <p>{formatIBAN(iban.iban)}</p>
                          </div>
                        </li>
                      )
                    })}
                  </ul>
                )}
              </div>
              <hr className='modal_hr_button' />
              <div className='manual_payout_infos'>

                <div className='manual_payout_form_item'>
                  <label htmlFor='custom-iban'>Custom IBAN</label>
                  <input
                    id='custom-iban-input'
                    type="text"
                    placeholder='Entrer un IBAN'
                    className='manual_payout_input_fields'
                    value={customIban}
                    onChange={handleCustomIban}
                  />
                </div>
                <div className='manual_payout_form_item'>
                  <label htmlFor="amount">Montant</label>
                  <input
                    id="amount"
                    className='manual_payout_input_fields'
                    type="text"
                    value={amount}
                    onInput={(e) => handleAmount('amount', e)} />
                </div>
                <div className='manual_payout_form_item' onClick={handleFees}>
                  <input
                    type="checkbox"
                    name="fees"
                    id="fees"
                    checked={fees}
                    onChange={() => { }}
                  />
                  <label>Inclure les commissions Goliaths et Revolut</label>
                </div>
                <div className='manual_payout_form_item' onClick={handleCustomFees}>
                  <input
                    type="checkbox"
                    name="customFees"
                    id="customFees"
                    checked={customFees}
                    onChange={() => { }}
                  />
                  <label>Frais personnalisés : </label>
                </div>
                <div className='manual_payout_custom_fees'>
                  <label>Frais de plateforme (Goliaths) : </label>
                  <input
                    id="goliathsCustomFees"
                    className='manual_payout_input_fields'
                    type="text"
                    value={goliathsCustomFees}
                    onInput={(e) => handleAmount('goliathsCustomFees', e)}
                    disabled={!customFees}
                  />
                </div>
                <div className='manual_payout_custom_fees'>
                  <label>Frais Revolut : </label>
                  <input
                    id="revolutCustomFees"
                    className='manual_payout_input_fields'
                    type="text"
                    value={revolutCustomFees}
                    onInput={(e) => handleAmount('revolutCustomFees', e)}
                    disabled={!customFees}
                  />
                </div>
                <input type="submit" className='manual_payout_button_submit' value="Valider" disabled={!isValid} />
              </div>

            </form>
          </div>
        </div>

        {
          isModalOpen && (
            <ValidationModal isModalOpen={isModalOpen} onClose={() => setIsModalOpen(false)} handleSubmitCode={handleSubmitCode} setCode={setCode} />
          )
        }

      </div >
    </DashboardLayout >
  )
}

export default ManualPayout