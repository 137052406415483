import axios from "axios";
import { API_BASE_URL, buildHeaders } from "utils/services";

export const fetchUtilityBills = async (user, page, limit, details) => {

    try {
        const params = `?page=${page}&limit=${limit}&details=${details}`
        const { data } = await axios.get(`${API_BASE_URL}/documents/utility-bills/pending/list${params}`,
            buildHeaders(user))
        return data
    } catch (err) {
        throw err
    }

}

export const fetchUtilityBillDetails = async (user, id) => {
    try {
        const { data } = await axios.get(`${API_BASE_URL}/documents/utility-bills/${id}`, buildHeaders(user))
        return data
    } catch (err) {
        throw err
    }

}

export const approveUtilityBill = async (user, utility_id) => {
    try {
        await axios.post(`${API_BASE_URL}/documents/utility-bills/approve`,
            { id: utility_id },
            buildHeaders(user))
    } catch (error) {
        console.error(error)
        throw error;
    }
}

export const rejectUtilityBill = async (user, utility_id, reason) => {
    try {
        await axios.post(`${API_BASE_URL}/documents/utility-bills/reject`,
            { id: utility_id, reason },
            buildHeaders(user))
    } catch (error) {
        console.error(error)
        throw error;
    }
}
