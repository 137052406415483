// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* giftFilters.css */
.marginTickerComponent{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

}

.userFilterSelect {
    width: unset;
    padding: unset;
    padding-left: 2px;
    margin-right: 1px;
    border: 1px solid #495057;
}`, "",{"version":3,"sources":["webpack://./src/components/Filters/MarginTickerFilter/marginTickerFilter.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,SAAS;;AAEb;;AAEA;IACI,YAAY;IACZ,cAAc;IACd,iBAAiB;IACjB,iBAAiB;IACjB,yBAAyB;AAC7B","sourcesContent":["/* giftFilters.css */\n.marginTickerComponent{\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    gap: 10px;\n\n}\n\n.userFilterSelect {\n    width: unset;\n    padding: unset;\n    padding-left: 2px;\n    margin-right: 1px;\n    border: 1px solid #495057;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
