import { useState, useEffect, useContext } from 'react'
import AuthContext from 'contexte/AuthContext'
import { fetchLeverageUserPositions } from 'services/fetchUserById'
import PageLoader from 'components/pageLoader/PageLoader'
import Tableau from 'components/DataComponents/Tableau/Tableau'
import './UserLeveragePositions.css'
import { useTranslation } from 'react-i18next';
import Notification from 'components/notifications/Notification'
import { launchError, launchSuccess } from 'components/notifications'
import TickerImage from 'components/DataComponents/TickerImage/TickerImage'
import { closeUserMarginPosition } from 'services/portfolioServices'
import Modal from 'components/modal/Modal';

const UserLeveragePositions = ({ id }) => {

  const { user } = useContext(AuthContext)
  const { t } = useTranslation()

  const [loading, setLoading] = useState(true)
  const [portfolio, setPortfolio] = useState([])
  const [page, setPage] = useState(0)
  const [fetchLimit, setFetchLimit] = useState(10)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [closeMarginPositionData, setCloseMarginPositionData] = useState({})

  const tableHeaders = ['Ticker', 'Quantité', 'Argent emprunté', 'Argent investis', 'Coût de base', 'Valeur actuelle', 'Plus value latente', 'Clôturer']

  const transformeDataToTbody = (data) => {
    return data.length && data.map((item, index) => {
      if (!item) return { data: [] }


      return {
        data: [
          <TickerImage ticker={item.ticker} />,
          item.qty,
          item.borrowed_amount,
          item.invested_liquidity,
          item.cost_basis,
          item.marketValue,
          <div className={`user_leverage_position_cell_${item.unrealizedPl >= 0 ? 'gain' : 'loss'}`}>{item.unrealizedPl} {item.unrealizedPlpc ? `(${item.unrealizedPlpc}%)` : null}</div>,
          <button className='close_margin_position_button' onClick={() => openModal(index)} key={item.id}>Clôturer</button>
        ],
      }
    })
  }

  const openModal = (index) => {
    setCloseMarginPositionData(portfolio[index]);
    setIsModalOpen(true)
  }


  const fetchData = async () => {
    try {
      const data = await fetchLeverageUserPositions(user, id, page, fetchLimit)
      setPortfolio(data)
      setLoading(false)
    } catch {
      console.log('error')
    }
  }

  useEffect(() => {
    fetchData()
  }, [page])

  const closeMarginPosition = async (ticker) => {
    try {
      setIsModalOpen(false)
      setLoading(true)
      await closeUserMarginPosition(user, { user_uuid: id, ticker });
      launchSuccess(t('notifications.users.userLeveragePositions.closeMarginPosition.success'))
    } catch (error) {
      console.error(error)
      launchError(t('notifications.users.userLeveragePositions.closeMarginPosition.error'));
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      {
        !loading ? (
          <div>
            <Notification />
            <Tableau
              tbody={transformeDataToTbody(portfolio)}
              thead={tableHeaders}
              limit={fetchLimit}
              pagination={true}
              setPage={setPage}
              page={page}
            />
            <ModalCloseMarginPosition
              isModalOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              closeMarginPositionData={closeMarginPositionData}
              closeMarginPosition={closeMarginPosition}
            />
          </div>
        ) : (
          <div className='pageLoaderBox'>
            <PageLoader />
          </div>
        )
      }
    </div>
  )
}


const ModalCloseMarginPosition = ({ isModalOpen, onClose, closeMarginPositionData, closeMarginPosition }) => {

  const { t } = useTranslation()

  const { ticker, qty, marketValue } = closeMarginPositionData;


  return (
    <Modal isOpen={isModalOpen} onClose={onClose} closeButton={false} >
      <h2 className='modal-playlist_title'>Clôturer la position</h2>
      <div className="payout-details">


        <div className='warning_deleted'>
          <img src="https://assets-global.website-files.com/612cdb668b06dbb2a51f99ee/651e6e6668d310d564622c79_warning_img.png" alt="" width={25} />
          <span>Êtes-vous sûr de vouloir vendre <strong>{qty}</strong> action(s) <strong>{ticker} </strong>
            pour un montant <strong>≈ {marketValue} €</strong> ?
          </span>
        </div>
      </div>
      <hr className='modal_hr_button' />
      <div className='close_position_modal_button_div'>
        <button className='close' onClick={onClose}>{t("pages.payouts.buttons.close")}</button>
        <button className='validate' onClick={() => { closeMarginPosition(ticker) }}>Vendre</button>
      </div>
    </Modal>

  )
}

export default UserLeveragePositions