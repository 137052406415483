import { useState, useContext, useEffect } from 'react'
import AuthContext from 'contexte/AuthContext'
import Notification from 'components/notifications/Notification'
import { launchError, launchSuccess } from 'components/notifications'
import { useTranslation } from 'react-i18next';
import DashboardLayout from 'components/layout/dashboard/DashboardLayout';
import './etfEdition.css'
import { searchEtfs, updateEtf} from 'services/tickersServices';
import EtfEditionTabs from './EtfEditionTabs/EtfEditionTabs';


const EtfEdition = () => {

  const { user } = useContext(AuthContext)
  const { t } = useTranslation();
  const [etfs, setEtfs] = useState([]);
  const [selectedEtf, setSelectedEtf] = useState({});
  const [search, setSearch] = useState('')


  const handleSubmit = async (e) => {
    e.preventDefault()
    console.log('submit', selectedEtf);
    try {
      await updateEtf(user, selectedEtf._id, {
        components: selectedEtf.components,
        countries: selectedEtf.countries,
        sectors: selectedEtf.sectors
      })
      launchSuccess(t('notifications.actifs.etf_edition.success'))
      clearSelection();
    } catch (error) {
      console.error(error)
      launchError(t(`notifications.actifs.etf_edition.${error?.response?.data?.error || 'etf_edition_error'}`))
    }
  }

  const clearSelection = () => {
    setEtfs([])
    setSelectedEtf({});
    setSearch('');
  };

  const handleSearchTickers = async (searchTerm) => {
    if (searchTerm.length > 0) {
      try {
        const data = await searchEtfs(user, searchTerm)
        setEtfs(data)
      } catch (e) {
        launchError(t('notifications.actifs.etf_edition.searching'))
      }
    } else {
      setEtfs([])
    }
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      console.log(search)
      handleSearchTickers(search)
    }, 300)

    return () => clearTimeout(delayDebounceFn)
  }, [search])


  return (
    <DashboardLayout page={t("pages_name.etf_edition")}>
      <Notification />
      <div className='etf_edition_header_box'>
        <h2>{t('pages.etf_edition.title')}</h2>
      </div>
      <div>
        <form onSubmit={handleSubmit} className='etf_edition_form'>
          <div className='etf_edition_search_container'>
            {(!selectedEtf?.ticker) ? (
              <input
                type="text"
                placeholder='Rechercher un ETF'
                className='etf_edition_search_container_input'
                onChange={(e) => setSearch(e.target.value)}
              />
            ) : (
              <>
                <div className={`${selectedEtf?.ticker ? "etf_edition_search_ticker_selected" : "etf_edition_search_ticker_not_selected"} relative`}>
                  <span onClick={() => setSelectedEtf(selectedEtf)}>
                    <p>{selectedEtf?.ticker}</p>
                    <p>{selectedEtf?.isin}</p>
                  </span>
                  <div onClick={() => clearSelection()} className='etf_edition_search_delete_selected_item'>x</div>
                </div>
                <EtfEditionTabs etf={selectedEtf} editEtf={setSelectedEtf} />
              </>
            )}
            {(!selectedEtf?.ticker) && etfs.length > 0 && (
              <ul className='etf_edition_search_tickers_list_box'>
                {etfs.map((etf, index) => (
                  <li key={index} onClick={() => setSelectedEtf(etf)} className="etf_edition_search_ticker_not_selected">
                    <div className='etf_edition_result_search'>
                      <p>{etf.ticker}</p>
                      <p>{etf.isin}</p>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className='etf_edition_button_box'>
            <input type="submit" className='etf_edition_button_submit' disabled={!selectedEtf?.ticker} value="Valider" />
          </div>
        </form>
      </div>
    </DashboardLayout>
  )
}

export default EtfEdition