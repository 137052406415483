import { useState, useEffect, useContext } from 'react'
import AuthContext from 'contexte/AuthContext'
import './Discount.css'
import Notification from 'components/notifications/Notification'
import { launchError, launchSuccess } from 'components/notifications'
import { useTranslation } from 'react-i18next';
import DashboardLayout from 'components/layout/dashboard/DashboardLayout'
import DateRangeSelector from 'components/Filters/DateSelector/DateSelector'
import { fetchReduceFees, reducedUsersFees } from 'services/userServices'
import { formatDate } from 'utils/date'


const Discount = () => {

    const { user } = useContext(AuthContext)
    const { t } = useTranslation();
    const [percentage, setPercentage] = useState(0)
    const [quantity, setQuantity] = useState(0)
    const [loading, setLoading] = useState(false);
    const [platformFees, setPlatformFees] = useState(false);
    const [marginFees, setMarginFees] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [selectedDate, setSelectedDate] = useState({
        date1: { startDate: new Date() },
    });
    const [actualDiscount, setActualDiscount] = useState({});

    const clearForm = () => {
        setPercentage(0)
        setQuantity(0)
        setPlatformFees(false)
        setMarginFees(false)
        setSelectedDate({
            date1: { startDate: new Date() },
        })
    }

    const handleSubmit = async () => {
        try {
            if (isValid) {
                const result = await reducedUsersFees(user, { platformFees, marginFees, endDate: selectedDate?.date1?.startDate, percentage, quantity })
                setActualDiscount(result)
                launchSuccess(t('notifications.gifts.discount.success'))
                clearForm()
            }
        } catch (error) {
            launchError(t('notifications.gifts.discount.error'))
        }
    }

    useEffect(() => {
        const fetchActualDiscount = async () => {
            try {
                const data = await fetchReduceFees(user)
                setActualDiscount(data)
            } catch (error) {
                launchError(t('notifications.global.errors.fetch'))
            }
        }

        fetchActualDiscount();
    }, [user, t])

    useEffect(() => {
        const valid =
            !isNaN(percentage) &&
            percentage > 0 && percentage <= 100 &&
            !isNaN(quantity) &&
            quantity > 0 &&
            (platformFees || marginFees);
        setIsValid(valid)
    }, [percentage, platformFees, marginFees, quantity])

    return (
        <DashboardLayout page={t("pages_name.discount")}>
            <div>
                <Notification />
                <div>
                    <div className='discount_title_component'>
                        <h2>Nouvelle promotion</h2>
                    </div>
                    <div>
                        {
                            new Date(actualDiscount?.endDate) > new Date() &&
                            <div className={'discount_actual_container'}>
                                <h5>Promotion actuelle</h5>
                                <div className='discount_actual_infos_container'>
                                    <label>Date de fin : <strong>{formatDate({ date: actualDiscount?.endDate, month: 'long', time: true })}</strong></label>
                                    <label>Réduction : <strong>{actualDiscount?.percentage}</strong></label>
                                    <label>Free trades / utilisateur : <strong>{actualDiscount?.quantity}</strong></label>
                                    <label>Frais de plateforme trades : <strong>{actualDiscount?.platformFees ? 'Oui' : 'Non'}</strong></label>
                                    <label>Frais de plateforme margin : <strong>{actualDiscount?.marginFees ? 'Oui' : 'Non'}</strong></label>
                                </div>
                            </div>
                        }
                        <div onSubmit={handleSubmit}>
                            <div className='discount_item_box'>

                                {/* <DateRangeSelector
                                    selectedDate={selectedDate}
                                    setSelectedDate={setSelectedDate}
                                    simpleDate
                                /> */}
                                <label htmlFor='end-date-input'>Date de fin</label>
                                <DateRangeSelector
                                    id='end-date-input'
                                    selectedDate={selectedDate}
                                    setSelectedDate={setSelectedDate}
                                    simpleDate
                                    showTime
                                    minDate={new Date()}

                                />
                            </div>
                            <div className='discount_item_box'>
                                <label htmlFor="quantity">Nombre de trades gratuits</label>
                                <input
                                    id="quantity"
                                    className='discount_percentage_input'
                                    type="number"
                                    max={100}
                                    value={quantity}
                                    onInput={(e) => setQuantity(Number(e.target.value))} />
                            </div>
                            <div className='discount_item_box'>
                                <label htmlFor="percentage">Réduction (%)</label>
                                <input
                                    id="percentage"
                                    className='discount_percentage_input'
                                    type="number"
                                    max={100}
                                    value={percentage}
                                    onInput={(e) => setPercentage(Number(e.target.value))} />
                            </div>
                            <div className="discount_item_box">
                                <label htmlFor="platform-fees">Frais de transactions (simple)</label>
                                <label className="toggle" id="platform-fees">
                                    <input className="toggle-checkbox"
                                        type="checkbox"
                                        checked={platformFees}
                                        onChange={() => setPlatformFees(prevState => !prevState)}
                                    />
                                    <div className="toggle-switch"></div>
                                </label>
                            </div>

                            <div className="discount_item_box">
                                <label htmlFor="margin-fees">Frais de transactions margin</label>
                                <label className="toggle" id="margin-fees">
                                    <input className="toggle-checkbox"
                                        type="checkbox"
                                        checked={marginFees}
                                        onChange={() => setMarginFees(prevState => !prevState)}
                                    />
                                    <div className="toggle-switch"></div>
                                </label>
                            </div>

                            <input type="button" className='discount_button_submit' value="Valider" disabled={!isValid} onClick={() => handleSubmit()}/>

                        </div>
                    </div>
                </div>

            </div>
        </DashboardLayout>
    )
}

export default Discount