import { formatNumberWithSpaces } from 'utils/numbers';
import './exanteAlert.css';

const ExanteAlert = ({ available, isAdmin }) => {

  const getAlert = (available) => available?.tradesAvailable ?
    (
      <div>
        <div className={`exante_alert_box ${available?.tradesAvailable <= Number(process.env.REACT_APP_EXANTE_ALERT_THRESHOLD) ? 'un' : ''}sufficient_amount`} >
          <div >
            <div className='exante_alert_box_text'>
              <span>Argent disponible sur le compte trades Exante : {formatNumberWithSpaces(available.tradesAvailable)} € (dont {formatNumberWithSpaces(available.pendingBuy)} € d'achat limit)</span>
            </div>
          </div>
        </div>
        <div className={`exante_alert_box ${available?.marginAvailable <= Number(process.env.REACT_APP_EXANTE_ALERT_MARGIN_THRESHOLD) ? 'un' : ''}sufficient_amount`} >
          <div >
            <div className='exante_alert_box_text'>
              <span>Argent disponible sur le compte margin Exante : {formatNumberWithSpaces(available.marginAvailable)} € (dont {formatNumberWithSpaces(available.pendingLimitBuy)} € d'achat limit)</span>
            </div>
          </div>
        </div>
      </div>)
    :
    (
      <div className={`exante_alert_box unsufficient_amount`} >
        <div >
          <div className='exante_alert_box_text'>
            <span>L'argent disponible n'a pas pu être récupéré sur Exante</span>
          </div>
        </div>
      </div>)


  return isAdmin && (getAlert(available))

}

export default ExanteAlert