import { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next';
import './investmentPlans.css'
import DashboardLayout from 'components/layout/dashboard/DashboardLayout'
import AuthContext from 'contexte/AuthContext';
import FilterComponent from 'components/Filters/FilterComponent/FilterComponent';
import PageLoader from 'components/pageLoader/PageLoader';
import { launchError } from 'components/notifications';
import Notification from 'components/notifications/Notification';
import BarGraph from 'components/BarGraph/BarGraph';
import { formatDate } from 'utils/date';
import { startOfDay, startOfMonth } from 'date-fns'
import { fetchInvestmentPlansByDate, fetchInvestmentPlansForecast } from 'services/investmentPlansServices';

const InvestmentPlans = () => {

  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [plans, setPlans] = useState([]);
  const [forecast, setForecast] = useState({});
  const [selectedDate, setSelectedDate] = useState({
    date1: { startDate: startOfMonth(new Date()), endDate: new Date() },
    date2: { startDate: null, endDate: null },
  });
  const [timeframe, setTimeframe] = useState('day');

  const fetchInvestmentsPlans = async () => {
    try {
      setLoading(true)
      const plansByDate = await fetchInvestmentPlansByDate({
        user,
        startDate: selectedDate.date1.startDate,
        endDate: selectedDate.date1.endDate,
        timeframe
      })
      setPlans(plansByDate)
    } catch (error) {
      launchError(t('notifications.payouts.investment_plans.error.fetch_by_date'))
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const fetchForecast = async () => {
    try {
      setLoading(true)
      const forecastData = await fetchInvestmentPlansForecast({
        user,
        startDate: selectedDate.date1.startDate,
        endDate: selectedDate.date1.endDate,
      })
      setForecast(forecastData)
    } catch (error) {
      launchError(t('notifications.payouts.investment_plans.error.fetch_forecast'));
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (selectedDate.date1.startDate !== null && selectedDate.date1.endDate !== null) {
      fetchForecast()
    }
  }, [selectedDate])

  useEffect(() => {
    if (selectedDate.date1.startDate !== null && selectedDate.date1.endDate !== null) {
      fetchInvestmentsPlans()
    }
  }, [selectedDate, timeframe])

  const resetFilter = () => {
    setSelectedDate({
      date1: { startDate: startOfMonth(new Date()), endDate: new Date() },
      date2: { startDate: null, endDate: null },
    })
    setTimeframe('day')
  }

  const transformDataToGraph = (data) => {
    return data.map(item => ({ ...item, xAxis: formatDate({ date: item.begin }) }))
  }

  return (
    <DashboardLayout page={t("pages_name.investment_plans")}>
      <Notification />
      <div>
        {!loading ? (
          <div>
            <div className='externalDeposit_titleComponent'>
              <h2>Investment Plans</h2>
            </div>
            <div className='problems_filter_box'>
              <FilterComponent
                resetFilter={resetFilter}
                date
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                timeframeFilterConfig={['day', 'week', 'month', 'year']}
                timeframeFilter={timeframe}
                setTimeframeFilter={setTimeframe}
                maxDate={new Date()}
              />
            </div>
            <p>Montant investi du {formatDate({date: selectedDate.date1.startDate})} au
             {formatDate({date: selectedDate.date1.endDate})} : {forecast.total_amount} ({forecast.total_count})</p>
            <div>
            </div>
            <div className='graph_box'>
              <BarGraph
                title={'Montant investi sur la période'}
                data={transformDataToGraph(plans)}
                dataKey={'sum'}
                xAxis={'xAxis'}
              />
            </div>

          </div>
        ) : (
          <div className='pageLoaderBox'>
            <PageLoader />
          </div>
        )}
      </div>
    </DashboardLayout>
  )
}

export default InvestmentPlans