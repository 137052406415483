import { useState, useEffect, useContext } from 'react'
import DashboardLayout from 'components/layout/dashboard/DashboardLayout'
import { useTranslation } from 'react-i18next';
import './utilityBills.css'
import AuthContext from 'contexte/AuthContext';
import Tableau from 'components/DataComponents/Tableau/Tableau';
import FilterComponent from 'components/Filters/FilterComponent/FilterComponent';
import PageLoader from 'components/pageLoader/PageLoader';
import { RELEASE_DATE, formatDate } from 'utils/date';
import { fetchUtilityBills } from 'services/utilityBillServices';

const UtilityBills = () => {

    const { t } = useTranslation()
    const { user } = useContext(AuthContext)

    const [utilityBills, setUtilityBills] = useState([])
    const [loading, setLoading] = useState(true)
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(0)
    const [selectedDate, setSelectedDate] = useState({
        date1: { startDate: new Date(RELEASE_DATE), endDate: new Date() },
        date2: { startDate: null, endDate: null },
    });




    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchUtilityBills(user, page, limit, true)
                setUtilityBills(data)
                setLoading(false)
            } catch (e) {
                console.log(e)
            }
        }

        if (selectedDate.date1.startDate !== null && selectedDate.date1.endDate !== null) {
            fetchData()
        }
    }, [limit, page, selectedDate])

    const resetFilter = () => {
        setSelectedDate({
            date1: { startDate: new Date(RELEASE_DATE), endDate: new Date() },
            date2: { startDate: null, endDate: null },
        })
        setLimit(50)
    }

    const transformDataToTbody = (data) => {
        return data.map((item) => {
            return {
                data: [
                    `${item.firstName} ${item.lastName}`,
                    formatDate({date: item.upload_date, month: 'long', time: true}),
                ], utils: {
                    id: item._id
                }
            }
        })
    }

    return (
        <DashboardLayout page={t("pages_name.utility_bills")}>
            <div>
                {!loading ? (
                    <div>
                        <div className='externalDeposit_titleComponent'>
                            <h2>Liste Des Justificatif de domicile En Attente De Vérification</h2>
                            <FilterComponent
                                resetFilter={resetFilter}
                                limit={true}
                                date={true}
                                fetchLimit={limit}
                                setLimit={setLimit}
                                selectedDate={selectedDate}
                                setSelectedDate={setSelectedDate}
                            />
                        </div>

                        <div className="table-container">
                            <div className="table-column">
                                <Tableau
                                    thead={["Client", "Date d'upload"]}
                                    tbody={transformDataToTbody(utilityBills)}
                                    hadLink={true}
                                    linkPath={"/finances/utility-bills/"}
                                    limit={limit}
                                    pagination={true}
                                    setPage={setPage}
                                    page={page}
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className='pageLoaderBox'>
                        <PageLoader />
                    </div>
                )}
            </div>
        </DashboardLayout>
    )
}

export default UtilityBills