import axios from "axios";
import { API_BASE_URL, buildHeaders } from "utils/services";

export const fetchPortfolio = async (user, uuid, page, fetchLimit) => {

  try {
      const { data } = await axios.get(`${API_BASE_URL}/users/clients/${uuid}/portfolio?page=${page}&limit=${fetchLimit}`,
          buildHeaders(user)
      );
      return data;
  } catch (error) {
      throw error
  }
}

export const closeUserPosition = async (user, closePositionData) => {
  try {
    const { data } = await axios.post(`${API_BASE_URL}/positions/close`, closePositionData,
      buildHeaders(user)
    )
    return data
  } catch (error) {
    throw error
  }
}

export const closeUserMarginPosition = async (user, closeMarginPositionData) => {
  try {
    const { data } = await axios.post(`${API_BASE_URL}/positions/margin/close`, closeMarginPositionData,
      buildHeaders(user)
    )
    return data
  } catch (error) {
    throw error
  }
}