import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import './translation/i18next'
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthProvider } from './contexte/AuthContext';
import { ResponseProvider } from './contexte/ToasterContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

const app = <BrowserRouter>
  <GoogleOAuthProvider clientId="455186117211-moojgo6m5kcb0q0nmjj5s8fnjjk0k4qu.apps.googleusercontent.com">
    <AuthProvider>
      <ResponseProvider>
        <App />
      </ResponseProvider>
    </AuthProvider>
  </GoogleOAuthProvider>
</BrowserRouter>


root.render(
  process.env.REACT_APP_ENVIRONMENT !== 'production' ? <React.StrictMode>{app}</React.StrictMode>: app
);

reportWebVitals();
