import { useState, useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import DashboardLayout from 'components/layout/dashboard/DashboardLayout'
import './utilityBill.css'
import { useTranslation } from 'react-i18next';
import AuthContext from 'contexte/AuthContext';
import Notification from 'components/notifications/Notification';
import { launchSuccess, launchError } from 'components/notifications'
import { useNavigate } from 'react-router-dom';
import { formatIBAN } from 'utils/iban';
import { approveUtilityBill, fetchUtilityBillDetails, rejectUtilityBill } from 'services/utilityBillServices';
// import { ToastContainer, toast } from 'react-toastify';


const UtilityBill = (props) => {

    const { user } = useContext(AuthContext)
    const { t } = useTranslation()

    const navigate = useNavigate();
    const { id } = useParams()
    const [loading, setLoading] = useState(true)
    const [details, setDetails] = useState({})
    const [reason, setReason] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchUtilityBillDetails(user, id)
                setDetails(data)
                setLoading(false)
            } catch (e) {
                console.error(e)
            }
        }

        fetchData()
    }, [])


    const approve = async () => {
        try {
            await approveUtilityBill(user, id)
            navigate('/finances/utility-bills')
            launchSuccess(t('notifications.payouts.ibans.approve.success'));
        } catch (error) {
            console.log('error catch')
            launchError(t('notifications.payouts.ibans.approve.error'))
        }
    }

    const reject = async () => {
        try {
            await rejectUtilityBill(user, id, reason)
            navigate('/finances/utility-bills')
            launchSuccess(t('notifications.payouts.ibans.reject.success'));
        } catch (error) {
            console.log('error reject catch');
            launchError(t('notifications.payouts.ibans.reject.error'))
        }
    }

    return (
        <DashboardLayout page={`${t("pages_name.utility_bills")} / ${id}`}>
            <div className='w-full'>
                {
                    !loading ? (
                        <div className='w-full'>
                            <Notification />
                            <div className='utility_bill_header'>
                                <h2>Détails du justificatif de domicile</h2>
                            </div>
                            <div className='utility_bill_secion'>
                                <div className='utility_bill_userInformation'>
                                    <div className='utility_bill_userInformation_profil'>
                                        <div className='utility_bill_userInformation_profil_img' style={{ backgroundImage: `url(${process.env.REACT_APP_CDN_URL}/usr/pic/${details.user_uuid})` }} />
                                        <p>{details.firstName + " " + details.lastName}</p>
                                    </div>
                                    <div className='utility_bill_userInformation_infos'>
                                        <p><strong>Email</strong> : {details.email}</p>
                                        <p><strong>Téléphone</strong> : {details.phone}</p>
                                        <p><strong>Adresse</strong> : {details.address}</p>
                                    </div>
                                </div>

                                <div className='utility_bill_userInformation utility_bill_pdf_container'>
                                    <embed src={`${process.env.REACT_APP_CDN_URL}/${details.doc_url}`} className='utility_bill_embed_pdf' type="application/pdf" />
                                </div>

                                <div className='utility_bill_userInformation utility_bill_refusal_reason'>
                                    <select
                                        id="reasons_dropdown"
                                        onChange={(e) => setReason(e.target.value)}>
                                        <option value="">Sélectionner une raison de refus</option>
                                        <option value="name_not_match">Le nom ne correspond pas</option>
                                        <option value="address_not_match">L'adresse correspond pas</option>
                                        <option value="picture_not_match">L'image ne correspond pas</option>
                                    </select>
                                </div>

                                <div className='utility_bill_button_container'>
                                    <button className='utility_bill_refused'
                                        disabled={!reason}
                                        onClick={() => reject()}>Refuser l'IBAN</button>
                                    <button className='utility_bill_accepted'
                                        onClick={() => approve()}>Accepter l'IBAN</button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>Loading...</div>
                    )
                }
            </div>
        </DashboardLayout>
    )
}

export default UtilityBill