import './marginTickerFilter.css'

const MarginTickerFilter = ({
    setMarketCap, marketCap
}) => {
    return (
        <div className='marginTickerComponent'>
            <label htmlFor="select_market_cap">Market cap</label>
            <select
                id='select_market_cap'
                className='custom-select'
                value={marketCap}
                onChange={(e) => setMarketCap(e.target.value)}>
                <option value='' defaultValue>ALL</option>
                <option value='0-5'>0-5</option>
                <option value='5-10'>5-10</option>
                <option value='10-20'>10-20</option>
                <option value='20-50'>20-50</option>
                <option value='50-100'>50-100</option>
                <option value='100'>100+</option>
            </select>
        </div>
    )
}

export default MarginTickerFilter