import { useState, useContext, useEffect } from 'react'
import DashboardLayout from 'components/layout/dashboard/DashboardLayout'
import { useTranslation } from 'react-i18next';
import AuthContext from 'contexte/AuthContext';
import './structuredProductsTrades.css'
import Tableau from 'components/DataComponents/Tableau/Tableau';
import FilterComponent from 'components/Filters/FilterComponent/FilterComponent';
import PageLoader from 'components/pageLoader/PageLoader';
import { fetchStructuredProductsTrades } from 'services/structuredProductsServices';
import TableUser from 'components/TableUser/TableUser';
import { formatDate, RELEASE_DATE } from 'utils/date';

const StructuredProductsTrades = () => {

    const { t } = useTranslation();
    const { user, email } = useContext(AuthContext)
    let isSuperAdmin = process.env.REACT_APP_SUPERADMIN_EMAILS.split(',')?.includes(email)

    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(0);
    const [trades, setTrades] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedDate, setSelectedDate] = useState({
        date1: { startDate: new Date(RELEASE_DATE), endDate: new Date() },
        date2: { startDate: null, endDate: null },
    });

    const fetchData = async () => {
        try {
            let data = [];
            if (isSuperAdmin) {
                data = await fetchStructuredProductsTrades({
                    user,
                    page,
                    limit,
                    startDate: selectedDate.date1.startDate,
                    endDate: selectedDate.date1.endDate,
                })
            }
            setTrades(data)
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }

    }

    useEffect(() => {
        if (selectedDate.date1.startDate !== null && selectedDate.date1.endDate !== null) {
            fetchData()
        }
    }, [limit, page, selectedDate.date1.startDate, selectedDate.date1.endDate])

    const transformDataToTbody = (data) => {
        return data.map((item) => {
            return {
                data: [
                    <TableUser
                        firstName={item.firstName}
                        lastName={item.lastName}
                        picture={item.user_uuid}
                        key={item.user_uuid}
                    />,
                    item.client_order_id,
                    item.product_name,
                    item.status,
                    item.volume,
                    item.fees,
                    formatDate({ date: item.created_at, time: true })
                ]
            }
        })
    }

    const resetFilter = () => {
        setSelectedDate({
            date1: { startDate: new Date(RELEASE_DATE), endDate: new Date() },
            date2: { startDate: null, endDate: null },
        })
        setLimit(10)
    }

    const handleLimit = (newLimit) => {
        setLimit(newLimit);
        setPage(0)
    }


    return (
        <DashboardLayout page={t("pages_name.structured_products_trades")}>
            <div>
                {!loading ? (
                    <div>
                        <div className='externalDeposit_titleComponent'>
                            <h2>Liste des transactions des produits structurés</h2>
                            <div className='orders_filter_components'>
                                <FilterComponent
                                    limit={true}
                                    date={true}
                                    fetchLimit={limit}
                                    setFetchLimit={handleLimit}
                                    resetFilter={resetFilter}
                                    selectedDate={selectedDate}
                                    setSelectedDate={setSelectedDate}

                                />
                            </div>

                        </div>
                        {isSuperAdmin ? <div className="table-container">
                            <div className="table-column">
                                <Tableau
                                    thead={["Utilisateur", "Transaction ID", "Produit", "Statut", "Montant", "Frais", "Date"]}
                                    tbody={transformDataToTbody(trades)}
                                    limit={limit}
                                    pagination
                                    page={page}
                                    setPage={setPage}
                                />
                            </div>
                        </div>
                            : <div className='insufficient_rights'>Insufficient rights to see this page</div>}
                    </div>
                ) : (
                    <div className='pageLoaderBox'>
                        <PageLoader />
                    </div>
                )}
            </div>
        </DashboardLayout>
    )
}

export default StructuredProductsTrades