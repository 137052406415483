import axios from "axios";
import { API_BASE_URL, buildHeaders } from "utils/services";

export const fetchPartnerKpi = async (user, uuid, timeframe) => {

  try {
      const { data } = await axios.get(`${API_BASE_URL}/partners/${uuid}/details?timeframe=${timeframe}`,
          buildHeaders(user)
      );
      return data;
  } catch (error) {
      throw error
  }
}

export const fetchPartnerCommissions = async (user, uuid, timeframe) => {

  try {
      const { data } = await axios.get(`${API_BASE_URL}/partners/${uuid}/commissions-repartition?timeframe=${timeframe}`,
          buildHeaders(user)
      );
      return data;
  } catch (error) {
      throw error
  }
}