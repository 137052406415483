import React, { useEffect, useState, useContext } from 'react'
import DashboardLayout from '../../../../components/layout/dashboard/DashboardLayout'
import { useTranslation } from 'react-i18next';
import './accounts.css'
import AuthContext from '../../../../contexte/AuthContext';
import { fetchAccountsIbanfirst } from 'services/fetchAccounts';
import Tableau from 'components/DataComponents/Tableau/Tableau';
import PageLoader from 'components/pageLoader/PageLoader';
import Badge from 'components/Badge/Badge';
import colors from 'assets/colors';
import { launchError } from 'components/notifications';
import Notification from 'components/notifications/Notification';

const Accounts = () => {

  const items = [
    {
      name: 'Stripe',
      key: 'stripe'
    },
    {
      name: 'Alpaca',
      key: 'alpaca'
    },
    {
      name: 'Revolut',
      key: 'revolut'
    },
    {
      name: 'Ibanfirst',
      key: 'ibanfirst'
    }
  ]

  const { t } = useTranslation()
  const { user } = useContext(AuthContext)

  const [accountData, setAccountData] = useState({})
  const [loading, setLoading] = useState(true)

  const fetchData = async () => {
    try {
      const accountsData = await fetchAccountsIbanfirst(user)

      console.log(accountsData)
      setAccountData(accountsData)
      setLoading(false)

    } catch (e) {
      launchError(t('notifications.global.errors.fetch'))
    }
  }

  useEffect(() => {
    fetchData();
  }, [user])

  function transformDataToTBody(key, data) {
    return data?.map(item => {
      const transformedData =
        [
          item.account_name || item.tag,
          Array.isArray(item?.balances) ? item.balances?.map((balance, index) => (
            <p key={index}>{balance.amount} {balance.currency}</p>
          )) :
            <p>{item.valueAmount.value} {item.valueAmount.currency}</p>,
        ]
      if (key !== 'ibanfirst') {
        transformedData.push(
          <Badge key={item.id || item.account_name} color={colors.badge[item.status?.toUpperCase()]}
            text={item.status?.toUpperCase()} />)
      }
      return {
        data: transformedData
      };
    });
  }

  const getTabHeaders = (key) => key === 'ibanfirst' ?
    [t("pages.comptes.account_name"), t("pages.comptes.balance")] :
    [t("pages.comptes.account_name"), t("pages.comptes.balance"), t("pages.comptes.status")]


  return (
    <DashboardLayout page={t("pages_name.finances_comptes")}>
      {!loading ? (
        <div>
          <Notification />
          <h2>{t("pages.comptes.title")}</h2>
          <div className="table-container">


            {
              items.map((item, index) => {
                return (
                  <div className="table-column" key={index}>
                    <h3>{item.name}</h3>
                    <Tableau
                      key={item.key}
                      thead={getTabHeaders(item.key)}
                      tbody={transformDataToTBody(item.key, accountData[item.key])}
                    />
                  </div>
                )
              })
            }

          </div>
        </div>
      ) : (
        <div className='pageLoaderBox'>
          <PageLoader />
        </div>
      )}
    </DashboardLayout>
  )
}

export default Accounts