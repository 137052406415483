import axios from "axios"
import { AGO_API_BASE_URL, API_BASE_URL, buildHeaders } from "utils/services"

export const fetchMainAction = async (user) => {
    
    try {
        const [payouts, deposits, ibans, utilityBill, agoDeposits, agoWithdraws] = await Promise.all([
            axios.get(`${API_BASE_URL}/payouts/pending/list?page=0&limit=50`, buildHeaders(user)),
            axios.get(`${API_BASE_URL}/deposits/external/list?page=0&status=ON%20HOLD&limit=10`, buildHeaders(user)),
            axios.get(`${API_BASE_URL}/payouts/iban-verifications/pending/list?page=0&limit=50`, buildHeaders(user)),
            axios.get(`${API_BASE_URL}/documents/utility-bills/pending/list?page=0&limit=50&details=false`, buildHeaders(user)),
            axios.get(`${API_BASE_URL}/ago/deposits/external/list?page=0&status=ON%20HOLD&limit=10`, buildHeaders(user)),
            axios.get(`${AGO_API_BASE_URL}/agbk/payouts/pending/list?type=count`, buildHeaders(user))
        ])

        return {
            payouts: payouts.data,
            deposits: deposits.data,
            ibans: ibans.data,
            utilityBill: utilityBill.data,
            agoDeposits: agoDeposits.data,
            agoWithdraws: agoWithdraws.data
        }

    } catch (e) {
        console.log('error main action')
        console.log(e)
        // return [-1, -1, -1, -1, -1]
    }
}