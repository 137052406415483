import { useState, useEffect, useContext } from 'react'
import AuthContext from 'contexte/AuthContext'
import { closeUserPosition, fetchPortfolio } from 'services/portfolioServices'
import PageLoader from 'components/pageLoader/PageLoader'
import Tableau from 'components/DataComponents/Tableau/Tableau'
import './UserPortfolio.css'
import { useTranslation } from 'react-i18next';
import { fetchPlaylistData } from 'services/fetchPlaylistData'
import Notification from 'components/notifications/Notification'
import { launchError, launchSuccess } from 'components/notifications'
import ScrollBar from 'components/Scrollbar/Scrollbar'
import TickerImage from 'components/DataComponents/TickerImage/TickerImage'
import Modal from 'components/modal/Modal';


const transformeDataToTbody = (data, callFromModal = false, callback) => {
  const sortedData = [...data]

  if (callFromModal) {
    let total = sortedData.shift();
    sortedData.push(total)
  }

  const getComponent = (item, isPlaylist) => {
    if (isPlaylist) {
      return (
        <div>
          <p>{item.playlistName}</p>
        </div>
      )
    } else if (item.ticker === 'TOTAL') {
      return (
        <div className="text-xs font-weight-bold me-3" style={{ color: "#000000" }}>{item.ticker}</div>
      )
    } else {
      return (
        <TickerImage ticker={item.ticker} />
      )
    }
  }

  return sortedData.length && sortedData.map((item, index) => {
    if (!item) return { data: [] }

    const isPlaylist = !item.playlist_id?.startsWith('UNIQUE') && !callFromModal

    const data = [
      <div className='arrayTickerItem'>
        {getComponent(item, isPlaylist)}
      </div>,
      isPlaylist ? '-> voir détail' : item.qty,
      isPlaylist ? '-> voir détail' : item.avg_entry_price,
      isPlaylist ? '-> voir détail' : item.cost_basis,
      isPlaylist ? '-> voir détail' : item.marketValue,
      isPlaylist ? '-> voir détail' : <div className={`portfolio_line_${item.unrealizedPl >= 0 ? 'gain' : 'loss'}`}>{item.unrealizedPl} {item.unrealizedPlpc ? `(${item.unrealizedPlpc}%)` : null}</div>,
    ]

    if (!callFromModal) {
      data.push(<button className='close_position_button' onClick={(event) => callback(event, 'close_position', index)} key={item.id}>Clôturer</button>)
    }

    return {
      data
    }
  })
}


const UserPortfolio = ({ id }) => {

  const { user } = useContext(AuthContext)
  const { t } = useTranslation()

  const [loading, setLoading] = useState(true)
  const [portfolio, setPortfolio] = useState([])
  const [page, setPage] = useState(0)
  const [fetchLimit, setFetchLimit] = useState(10)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState('');
  const [playlistData, setPlaylistData] = useState([]);
  const [playlistName, setPlaylistName] = useState(null);
  const [closePositionData, setClosePositionData] = useState({})

  const tableHeaders = ['Ticker', 'Quantité', "Prix d'entrée moyen", 'Coût de base', 'Valeur actuelle', 'Plus value latente', 'Clôturer']


  const fetchData = async () => {
    try {
      const data = await fetchPortfolio(user, id, page, fetchLimit)
      setPortfolio(data)
      setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [page])

  const openModal = (event, type, index) => {
    event?.stopPropagation();
    const selectedRow = portfolio[index];
    setModalType(type)
    if (type === 'playlist_details' && selectedRow.type === 'PLAYLIST') {
      fetchPlaylistDetail(selectedRow.playlist_id)
      setPlaylistName(selectedRow.playlistName)
      setIsModalOpen(true);
    } else if (type === 'close_position') {
      setClosePositionData(selectedRow);
      setIsModalOpen(true)
    }
  }

  const closeModal = () => {
    setIsModalOpen(false);
    setModalType('')
  }


  const fetchPlaylistDetail = async (playlist_id) => {
    try {
      setLoading(true)
      const data = await fetchPlaylistData(user, id, playlist_id);
      setPlaylistData(data);
    } catch (error) {
      launchError({ message: 'Error getting playlist detail' });
    } finally {
      setLoading(false);
    }
  }

  const closePosition = async ({ ticker, playlist_id, type }) => {
    try {
      closeModal()
      setLoading(true)
      const closePositionData = { user_uuid: id }
      if (type === 'PLAYLIST')
        closePositionData['playlist_id'] = playlist_id;
      else
        closePositionData['ticker'] = ticker;

      const data = await closeUserPosition(user, closePositionData);
      launchSuccess(t('notifications.users.userPortfolio.closePosition.success'))
    } catch (error) {
      launchError(t('notifications.users.userPortfolio.closePosition.error'));
    } finally {
      setLoading(false)
    }
  }


  return (
    <div>
      {
        !loading ? (
          <div>
            <Notification />
            <Tableau
              tbody={transformeDataToTbody(portfolio, false, openModal)}
              thead={tableHeaders}
              limit={fetchLimit}
              pagination={true}
              setPage={setPage}
              page={page}
              hadModal
              onClickFunction={(index) => openModal(null, 'playlist_details', index)}
            />
            <ModalPlaylistDetail
              isModalOpen={isModalOpen}
              modalType={modalType}
              onClose={closeModal}
              playlistData={playlistData}
              tableHeaders={[...tableHeaders].slice(0, -1)}
              playlistName={playlistName}
            />
            <ModalClosePosition
              isModalOpen={isModalOpen}
              modalType={modalType}
              onClose={closeModal}
              closePositionData={closePositionData}
              closePosition={closePosition}
            />
          </div>
        ) : (
          <div className='pageLoaderBox'>
            <PageLoader />
          </div>
        )
      }

    </div>
  )
}

export default UserPortfolio


const ModalPlaylistDetail = ({ isModalOpen, onClose, playlistData, playlistName, tableHeaders, modalType }) => {
  const modalClass = isModalOpen && modalType === 'playlist_details' ? 'modal-playlist-overlay open' : 'modal-playlist-overlay';

  const { t } = useTranslation()

  return (
    <div className={modalClass}>
      <div className="modal-playlist-container">
        <button className="close-button" onClick={onClose}>
          X
        </button>
        <h2 className='modal-playlist_title'>{t("pages.user.portfolio.modalPlaylistDetails.title", { playlistName })}</h2>
        <div className="playlist-details">
          <ScrollBar className='modal-playlist-scrollbar'>
            <Tableau
              tbody={transformeDataToTbody(playlistData, true)}
              thead={tableHeaders}
            />
          </ScrollBar>
        </div>
        <div className='modal-playlist-button-div'>
          <button className='close' onClick={onClose}>{t("pages.user.portfolio.modalPlaylistDetails.close")}</button>
        </div>
      </div>
    </div>
  )
}

const ModalClosePosition = ({ isModalOpen, onClose, modalType, closePositionData, closePosition }) => {

  const { t } = useTranslation()

  const { playlist_id, playlistName, ticker, qty, type } = closePositionData;


  return (
    <Modal isOpen={isModalOpen && modalType === 'close_position'} onClose={onClose} closeButton={false} >
      <h2 className='modal-playlist_title'>Clôturer la position</h2>
      <div className="payout-details">


        <div className='warning_deleted'>
          <img src="https://assets-global.website-files.com/612cdb668b06dbb2a51f99ee/651e6e6668d310d564622c79_warning_img.png" alt="" width={25} />
          <span>Êtes-vous sûr de vouloir
            {type === 'PLAYLIST' ? <> clôturer les positions de la playlist <strong>{playlistName} </strong></> :
              <> vendre <strong>{qty}</strong> action(s) <strong>{ticker} </strong></>}
            pour un montant <strong>≈ {closePositionData.marketValue} €</strong> ?
          </span>
        </div>
      </div>
      <hr className='modal_hr_button' />
      <div className='close_position_modal_button_div'>
        <button className='close' onClick={onClose}>{t("pages.payouts.buttons.close")}</button>
        <button className='validate' onClick={() => { closePosition({ playlist_id, ticker, type }) }}>Vendre</button>
      </div>
    </Modal>

  )
}